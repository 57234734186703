import { useEffect, useState, useContext } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { ThreeDots } from 'react-loading-icons'
import { Button, Form, Table } from 'react-bootstrap';
import BannerHeader from "../assets/images/quiz/quiz.png"
import Subheader from "./reusables/Subheader";
import Lessons from "../assets/lessons/sql";
import axios from 'axios';
import SButton from './reusables/SButton';
import AppContext from "./reusables/AppContext";

const Quiz = () => {
    const params = useParams();
    const location = useLocation();

    const [start, setStart] = useState(true);
    const [test, setTest] = useState(null);
    const [query, setQuery] = useState('');
    const [output, setOutput] = useState(null);
    const [error, setError] = useState(null);
    const [queryToSubmit, setQueryToSubmit] = useState(null);
    const [loadingQuiz, setLoadingQuiz] = useState(false);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    const { id: quizId, lessonKey } = params;
    const quizNumber = quizId.split('-')[1].replace('Q','');
    const { user, updateUserProgress } = useContext(AppContext);
    const attempts = (user && user['queries-scored']) 
        ? user['queries-scored'][quizId] 
        : null;
    const attemptsLength = attempts ? Object.keys(attempts).length : null

    const url = process.env.REACT_APP_API_ENDPOINT;
    const config = {
        headers: {
            idToken: user?.idToken
        }
    }

    const resetQuiz = () => {
        setQuery('');
        setOutput(null);
        setError(null);
        setQueryToSubmit(null);
        setLoadingQuiz(false)
        setLoading(false);
        setResult(null)
    }

    useEffect(() => {
        setLoadingQuiz(true);
        if (quizId.includes('-Q1')) {
            setStart(false);
        }
        // to make endpoint a env variable
        const endPoint = `${url}/fetchQuizDetails`;
        axios.get(endPoint, {
            headers: { idToken: user?.idToken },
            params: { lesson: quizId }
        }).then(res => {
            setTest(res.data);
            setLoadingQuiz(false);
            resetQuiz()
        });
    }, [location.pathname]);

    const handleQuery = () => {
        setLoading(true);
        setQueryToSubmit(query);
        axios.post(`${url}/bigQuery`, {
            query
        }, config).then(res => {
            setOutput(res.data);
            setError(null);
            setLoading(false);
        }).catch(err => {
            setError(err.response.data.message)
            setOutput(null)
            setQueryToSubmit(null);
            setLoading(false);
        });
    }

    const handleSubmit = () => {
        setLoading(true);
        axios.post(`${url}/quizSubmit`, {
            query: queryToSubmit,
            quizId,
        }, config).then(res => {
            updateUserProgress(user.uid);
            setResult(res.data);
            setQueryToSubmit(null);
            setError(null);
            setLoading(false);
        }).catch(err => {
            // setError(err.response.data.message)
            setOutput(null);
            setLoading(false);
        });
    }

    const renderOutput = (data) => {
        let toReturn = <i>Nothing to Display</i>;
        if (data && data.length > 0) {
            const headers = Object.keys(data[0]);
            toReturn = (
                <div>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th></th>
                                {
                                    headers.map((header) => {
                                        return <th>{header}</th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map((dat, index) => {
                                    return (
                                        <tr>
                                            <th>
                                                { index }
                                            </th>
                                            {
                                                headers.map((header) => {
                                                    return (
                                                        <td>
                                                            {(dat[header] && typeof dat[header] === 'object') ?
                                                                dat[header].value 
                                                                : dat[header]
                                                            }
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            )
        }
        return toReturn;
    }

    const renderTextAreaScore = () => {
        const renderScore = () => {
            const nextLink = test?.data.next || '/progress';
            return (
                <div className="quiz-score">
                    <p>Your Score</p>
                    <p>{result?.boolFinalAnswer ? '10' : '0'}/10</p>
                    <p>Times Taken: <b>{ attemptsLength }</b></p>
                    <div>
                        <SButton onClick={resetQuiz}>Retake Question</SButton>
                        { nextLink && (
                            <Link to={nextLink}>
                                <SButton>
                                    {test?.data.last 
                                        ? 'View Progress'
                                        : 'Next Question'
                                    }
                                </SButton>
                            </Link>
                        )}
                    </div>
                </div>
            )
        }
        return result ? renderScore() : (
            <>
                <Form.Control
                    as="textarea" 
                    placeholder="Enter query here"
                    onChange={({target}) => {
                        setQuery(target.value)
                    }}
                    disabled={loading}
                />
                <div>
                    <SButton
                        className="view-output"
                        onClick={handleQuery}
                        disabled={loading}
                    >
                        View Output
                    </SButton>
                    <SButton
                        className="submit-answer"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        Submit Answer
                    </SButton>
                </div>
                <div>
                    <h3>Receiving Errors?</h3>
                    <ul>
                        <li>
                            <b>When using CAST(),</b> you can only convert it for now into BOOL, INT64, NUMERIC, BIGNUMERIC, FLOAT64, STRING, BYTES, DATE, DATETIME, TIME, or TIMESTAMP.
                        </li>
                        <li>
                            <b>When selecting all columns,</b> you can limit the rows to be produced by the query.
                        </li>
                    </ul>
                </div>
            </>
        )
    }

    const lesson = Lessons[lessonKey];
    const { 
        title: lessonTitle,
        questions
    } = lesson;

    return (
        <div className={`${!start ? 'quiz--hide' : 'quiz'}`}>
            <Subheader
                header={<span>📝 {lessonTitle}</span>}
                body={
                    <div>
                        <p>{questions.length} questions</p>
                        <p>Practice your skill on the basics of creating SQL queries. Using the tables: user_mart, item_mart, and order_mart, create a query that fetches the data asked per question.</p>
                        {!start && (
                            <Button 
                                size="lg" 
                                className="about__button"
                                onClick={() => { setStart(true); }}
                                disabled={loadingQuiz}
                            >
                                {loadingQuiz 
                                    ? <ThreeDots fill="#EE4D2D" />
                                    : 'Start Quiz'
                                }
                            </Button>
                        )}
                        {test && (
                            <p>{test.data.topics}</p>
                        )}
                    </div>
                }
                image={BannerHeader}
            />
            <div className={`quiz__body ${loadingQuiz ? 'loading' : ''}`}>
                {loadingQuiz 
                    ? (<ThreeDots fill="#EE4D2D" />)
                    : (
                        <>
                            <div className="quiz__body__test">
                                <div className={`
                                    quiz__body__test__textarea 
                                    ${queryToSubmit ? 'hasAnswer' : ''}
                                `}>
                                    <h2>Question #{quizNumber}</h2>
                                    <p>{test?.data.question}</p>
                                    <p dangerouslySetInnerHTML={{__html: test?.data.body}}/>
                                    { renderTextAreaScore() }
                                </div>
                                <div className="quiz__body__test__output">
                                    <h2>Expected Output</h2>
                                    <p>Tables used: <i>{test?.data.tables.join(', ')}</i></p>
                                    { renderOutput(test?.responseQuery) }

                                    <h2>Your Output</h2>
                                    { loading ? 
                                        (<div style={{textAlign: 'center'}}><ThreeDots fill="#EE4D2D" /></div>) 
                                        : (error || renderOutput(output)) 
                                    }
                                </div>
                            </div>
                            <div className="quiz__body__footer">
                                <h2>Tables</h2>
                                <div>
                                    <div>
                                        <h3>user_mart</h3>
                                        <p>Commonly Used Columns: user_id, shop_id, registration_datetime, is_email_verified, is_phone_verified, is_seller, status</p>
                                    </div>
                                    <div>
                                        <h3>item_mart</h3>
                                        <p>Commonly Used Columns: user_id, shop_id, registration_datetime, is_email_verified, is_phone_verified, is_seller, status</p>
                                    </div>
                                    <div>
                                        <h3>order_mart</h3>
                                        <p>Commonly Used Columns: user_id, shop_id, registration_datetime, is_email_verified, is_phone_verified, is_seller, status</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default Quiz;