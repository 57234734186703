import { useParams } from "react-router-dom";
import LessonLayout from "./LessonLayout";
import lessons from '../../assets/lessons/presto';
import Subheader from '../reusables/Subheader';

const PrestoLesson = () => {
    const { lessonKey } = useParams();
    const { lesson } = lessons[lessonKey];
    return (
        <div className="prestoLessons">
            <Subheader 
                header="Presto SQL Optimization"
                body={
                    <div>
                        <p>4 lessons</p>
                        <p>Learn how to optimize your Presto SQL queries through this module.</p>
                    </div>
                }
            />
            <LessonLayout
                lesson={lesson}
                lessonKey={lessonKey}
                lessonPlan="PrestoSQLOptimization"
            />
        </div>
    )
}

export default PrestoLesson;