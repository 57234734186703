import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Button from './reusables/SButton';
import { ThreeDots } from 'react-loading-icons'
import { getAuth, sendPasswordResetEmail  } from "firebase/auth";

import Shopee from '../assets/images/logos/shopee_landing.png';
import Reset from '../assets/images/forgetPassword/reset.png';

const ForgetPassword = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        setLoading(true)
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
          .then(() => {
            // Password reset email sent!
            setLoading(false);
            alert('An email to reset your password is successfully sent.');
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorMessage);
            alert(errorMessage)
            setLoading(false);
          });
    }

    return (
        <div className="forgetPassword">
            <section className="forgetPassword__header">
                <div className="forgetPassword__header__text">
                    <h2>Let's start your journey</h2>
                    <img src={Reset} />
                </div>
                <div className="forgetPassword__header__form">
                    <img src={Shopee} />
                    <h2>Query Training Portal</h2>
                    <Form.Group controlId="forgetPasswordEmail">
                        <Form.Label>Reset your password:</Form.Label>
                        <Form.Control 
                            type="email" 
                            placeholder="jackie.chan@shopee.com" 
                            onChange={({target}) => {setEmail(target.value)}}
                        />
                    </Form.Group>
                    <Button 
                        className="forgetPassword__register"
                        onClick={handleClick}
                        disabled={loading}
                    >
                        { loading 
                            ? <ThreeDots fill="#EE4D2D" /> 
                            : "Send Reset Link"
                        }
                    </Button>
                    <Link to="/login">
                        Back to Login Page
                    </Link>
                </div>
            </section>
        </div>
    );
}

export default ForgetPassword;