import { useParams } from "react-router-dom";
import LessonLayout from "./LessonLayout";
import lessons from '../../assets/lessons/sql';
import Subheader from '../reusables/Subheader';

const SQLLesson = () => {
    const { lessonKey } = useParams();
    const { lesson } = lessons[lessonKey];
    return (
        <div className="sqlLesson">
            <Subheader 
                header="SQL"
                body={
                    <div>
                        <p>5 lessons</p>
                        <p>Learn how to construct SQL queries to fetch data. This module covers basic, intermediate, and advanced lessons.</p>
                    </div>
                }
            />
            <LessonLayout
                lesson={lesson}
                lessonKey={lessonKey}
                lessonPlan="SQLTraining"
            />
        </div>
    )
}

export default SQLLesson;