import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Feedback from './Feedback';
import Subheader from "./reusables/Subheader";
import Info from "./reusables/Info";
import SButton from './reusables/SButton';

import BannerHeader from "../assets/images/dashboard/banner_header.png";
import SQLTrainingCard from "../assets/images/dashboard/SQL_Training_Card.png";
import PrestoCard from "../assets/images/dashboard/PrestoSQL_Optimization_Card.png";
import LumosCard from "../assets/images/dashboard/Lumos_User_Guide_Card.png";
import DocuCard from "../assets/images/dashboard/Presto_Documentation_Card.png";
import CourseIcon from "../assets/images/course-icon.png";
import FeedbackImage from "../assets/images/dashboard/feedback.png";
import AppContext from './reusables/AppContext';

const Dashboard = () => {
    const [show, setShow] = useState(false);
    const { user } = useContext(AppContext);
    const account = user ? user.account : {};
    
    return (
        <section className="dashboard">
            <Subheader 
                header={<span>Welcome to the Shopee Query Training Portal, <b>{account.name || account.email}</b></span>}
                body={
                    <div>
                        <p>Learn how to create SQL queries using the PrestoSQL Syntax and how to use Lumos to get your needed data.</p>
                        <p>Pick a module to explore today</p>
                    </div>
                }
                image={BannerHeader}
            />
            <div className="dashboard__courses">
                <h2>
                    <img src={CourseIcon} />
                    <span>Courses</span>
                </h2>
                <div className="dashboard__courses__container">
                    <Link to="/SQLTraining" className="dashboard__course">
                        <img src={SQLTrainingCard} />
                        <div>
                            <h2>SQL Training</h2>
                            <p>4 Lesson</p>
                        </div>
                    </Link>
                    <Link to="/PrestoSQLOptimization" className="dashboard__course">
                        <img src={PrestoCard} />
                        <div>
                            <h2>Presto SQL Optimization</h2>
                            <p>4 Lessons</p>
                        </div>
                    </Link>
                    <Link to="/LumosUserGuide" className="dashboard__course">
                        <img src={LumosCard} />
                        <div>
                            <h2>Lumos User Guide</h2>
                            <p>5 Lessons</p>
                        </div>
                    </Link>
                    <Link to="/PrestoDocumentation/Lesson1/1" className="dashboard__course">
                        <img src={DocuCard} />
                        <div>
                            <h2>Presto Documentation</h2>
                            <p>SQL Statement Syntax</p>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="dashboard__whatchuthink">
                <div className="dashboard__whatchuthink__card">
                    <img src={FeedbackImage} />
                    <div>
                        <h3>Let us know what you think</h3>
                        <p>Help us improve your experience in using the Query Training Portal</p>
                        <SButton onClick={() => {setShow(true)}}>
                            Send Feedback
                        </SButton>
                    </div>
                </div>
            </div>
            <Info />
            <Feedback
                show={show}
                handleClose={() => {setShow(false)}}
            />
        </section>
    )
}

export default Dashboard;