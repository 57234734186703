import { useEffect, useContext } from 'react';
import Subheader from '../reusables/Subheader';
import AppContext from '../reusables/AppContext';
import axios from 'axios';

const PrestoDocu = () => {
    const { user: { idToken }} = useContext(AppContext);
    const config = {
        headers: {
            idToken
        }
    }

    useEffect(() => {
        const url = process.env.REACT_APP_API_ENDPOINT;
        axios.post(`${url}/savePageProgress`, {
            pathname: '/PrestoDocumentation/Lesson1/1'
        }, config).then(res => {
            
        }).catch(err => {

        });        
    }, []);

    return (
        <div className="prestoDocu">
            <Subheader 
                header="Presto Documentation"
                body={
                    <div>
                        <p>Presto SQL Statement Syntax</p>
                        <p>Refer to the Presto Documentation for further tips on how to use the Presto SQL Statement Syntax.</p>
                    </div>
                }
            />
            <div className="prestoDocu__body">
                <h2>SQL Statement Syntax</h2>
                <div className="prestoDocu__list">
                    <div className="prestoDocu__list__left">
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/alter-function.html" target="_blank">ALTER FUNCTION</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/alter-schema.html" target="_blank">ALTER SCHEMA</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/alter-table.html" target="_blank">ALTER TABLE</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/analyze.html" target="_blank">ANALYZE</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/call.html" target="_blank">CALL</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/commit.html" target="_blank">COMMIT</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/create-function.html" target="_blank">CREATE
                                FUNCTION</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/create-role.html" target="_blank">CREATE ROLE</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/create-schema.html" target="_blank">CREATE SCHEMA</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/create-table.html" target="_blank">CREATE TABLE</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/create-table-as.html" target="_blank">CREATE TABLE
                                AS</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/deallocate-prepare.html" target="_blank">DEALLOCATE
                                PREPARE</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/delete.html" target="_blank">DELETE</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/describe.html" target="_blank">DESCRIBE</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/describe-input.html" target="_blank">DESCRIBE
                                INPUT</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/describe-output.html" target="_blank">DESCRIBE
                                OUTPUT</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/drop-function.html" target="_blank">DROP FUNCTION</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/drop-role.html" target="_blank">DROP ROLE</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/drop-schema.html" target="_blank">DROP SCHEMA</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/drop-table.html" target="_blank">DROP TABLE</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/drop-view.html" target="_blank">DROP VIEW</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/execute.html" target="_blank">EXECUTE</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/explain.html" target="_blank">EXPLAIN</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/explain-analyze.html" target="_blank">EXPLAIN
                                ANALYZE</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/grant.html" target="_blank">GRANT</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/grant-roles.html" target="_blank">GRANT ROLES</a>
                        </div>
                    </div>
                    <div className="prestoDocu__list__right">
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/insert.html" target="_blank">INSERT</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/prepare.html" target="_blank">PREPARE</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/reset-session.html" target="_blank">RESET SESSION</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/revoke.html" target="_blank">REVOKE</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/revoke-roles.html" target="_blank">REVOKE ROLES</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/rollback.html" target="_blank">ROLLBACK</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/select.html" target="_blank">SELECT</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/set-role.html" target="_blank">SET ROLE</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/set-session.html" target="_blank">SET SESSION</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/show-catalogs.html" target="_blank">SHOW CATALOGS</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/show-columns.html" target="_blank">SHOW COLUMNS</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/show-create-function.html" target="_blank">SHOW CREATE
                                FUNCTION</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/show-create-table.html" target="_blank">SHOW CREATE
                                TABLE</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/show-create-view.html" target="_blank">SHOW CREATE
                                VIEW</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/show-functions.html" target="_blank">SHOW
                                FUNCTIONS</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/show-grants.html" target="_blank">SHOW GRANTS</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/show-role-grants.html" target="_blank">SHOW ROLE
                                GRANTS</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/show-roles.html" target="_blank">SHOW ROLES</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/show-schemas.html" target="_blank">SHOW SCHEMAS</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/show-session.html" target="_blank">SHOW SESSION</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/show-stats.html" target="_blank">SHOW STATS</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/show-tables.html" target="_blank">SHOW TABLES</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/start-transaction.html" target="_blank">START
                                TRANSACTION</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/use.html" target="_blank">USE</a>
                        </div>
                        <div class="row">
                            <a href="https://prestodb.io/docs/current/sql/values.html" target="_blank">VALUES</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrestoDocu;