import React, { useEffect, useContext } from "react";
import { Button } from "react-bootstrap"
import { useParams, useLocation, Link } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import BookIcon from "../../assets/images/book_icon.png";
import SButton from '../reusables/SButton';
import AppContext from "../reusables/AppContext";
import axios from 'axios';

const toColor = [
    'HAVING',
    'MAX',
    'MIN',
    'AVG',
    'SUM',
    'DISTINCT',
    'FROM_UNIXTIME',
    'CAST',
    'SELECT', 
    'CASE',
    'ORDER BY', 
    'UNION ',
    'FROM',
    'WHERE',
    'LIKE',
    'WHEN',
    'AND',
    'CROSS JOIN',
    'LEFT JOIN',
    'RIGHT JOIN ',
    'FULL OUTER JOIN',
    'UNNEST',
    'JOIN',
    'COUNT',
    'GROUP BY',
    'GROUP',
    ' ON',
    ' ON ',
    ' AS',
    'AS ',
    ' AS ',
    'OR',
    ' IN ',
    ' IN',
    'IN '
];

const LessonLayout = (props) => {
    const params = useParams();
    const location = useLocation();
    const { pathname } = location;
    const { user: { idToken }} = useContext(AppContext);
    const url = process.env.REACT_APP_API_ENDPOINT;
    const config = {
        headers: {
            idToken
        }
    }

    const { lesson, lessonKey, lessonPlan } = props;
    const { id: lessonId } = params;

    useEffect(() => {
        axios.post(`${url}/savePageProgress`, {
            pathname
        }, config).then(res => {
            
        }).catch(err => {

        });        
    }, [pathname]);

    useEffect(() => {        
        const pars = document.querySelectorAll('p');
        for(let i = 0; i < pars.length; i++) {
            const par = pars[i];
            let result = par.innerHTML;
            toColor.forEach(clr => {
                result = result.replaceAll(clr, `<span class="shopee-orange">${clr}</span>`)
            })
            par.innerHTML = result;
        }
    }, []);

    const currentTopic = lesson[lessonId - 1];
    let nextLessonUrl = `/${lessonPlan}/${lessonKey}/${parseInt(lessonId) + 1}`;    
    let nextButtonLabel = 'Next';
    
    // minus one because array starts at 0
    if (currentTopic?.nextQuiz) {
        nextLessonUrl = currentTopic.nextQuiz;
        nextButtonLabel = 'Take Quiz';
    } else if (currentTopic?.next) {
        nextLessonUrl = currentTopic.next
        nextButtonLabel = 'Next Lesson';
    } else if (currentTopic?.last) {
        nextLessonUrl = '/progress';
        nextButtonLabel = 'View Progress';
    } else if (lessonId === lesson.length.toString()) {
        nextLessonUrl = '';
    }

    return (
        <div className="lessonLayout">
            <Tab.Container activeKey={lessonId} defaultActiveKey="2">
                <Nav variant="pills" className="lessonLayout__outline">
                    <h2>
                        <img src={BookIcon} />
                        Lesson Outline
                    </h2>
                    {
                        lesson.map((topic, index) => {
                            return (
                                <Nav.Item>
                                    <Link to={`/${lessonPlan}/${lessonKey}/${index + 1}`}>
                                        <Nav.Link eventKey={index + 1}>
                                            {topic.outline}
                                        </Nav.Link>
                                    </Link>
                                </Nav.Item>
                            )
                        })
                    }
                </Nav>
                <Tab.Content className="lessonLayout__content">
                    {
                        lesson.map((topic, index) => {
                            return (
                                <Tab.Pane eventKey={`${index + 1}`}>
                                    { topic.content }
                                </Tab.Pane>
                            )
                        })
                    }
                    <div className="lessonLayout__paginate">
                        <Link to={`/${lessonPlan}/${lessonKey}/${parseInt(lessonId) - 1}`}>
                            <Button 
                                className={
                                    lessonId === '1' 
                                        ? 'hidden' 
                                        : ''
                                }
                                variant="secondary"
                            >
                                Previous
                            </Button>
                        </Link>
                        <Link to={nextLessonUrl}>
                            <SButton
                                className={
                                    nextLessonUrl === ''
                                        ? 'hidden' 
                                        : ''
                                }
                            >
                                { nextButtonLabel }
                            </SButton>
                        </Link>
                    </div>
                </Tab.Content>
            </Tab.Container>
        </div>
    )
}

export default LessonLayout;