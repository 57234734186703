import Lesson1Pic1 from '../images/lessons/lumos/m3l1-1.png';
import Lesson1Pic2 from '../images/lessons/lumos/m3l1-2.png';
import Lesson1Pic3 from '../images/lessons/lumos/m3l1-3.png';
import Lesson1Pic4 from '../images/lessons/lumos/m3l1-4.png';
import Lesson1Pic5 from '../images/lessons/lumos/m3l1-5.png';
import Lesson1Pic6 from '../images/lessons/lumos/m3l1-6.png';
import Lesson1Pic7 from '../images/lessons/lumos/m3l1-7.png';
import Lesson1Pic8 from '../images/lessons/lumos/m3l1-8.png';

import Lesson2Pic1 from '../images/lessons/lumos/m3l2-1.png';
import Lesson2Pic2 from '../images/lessons/lumos/m3l2-2.png';
import Lesson2Pic3 from '../images/lessons/lumos/m3l2-3.png';
import Lesson2Pic4 from '../images/lessons/lumos/m3l2-4.png';
import Lesson2Pic5 from '../images/lessons/lumos/m3l2-5.png';
import Lesson2Pic6 from '../images/lessons/lumos/m3l2-6.png';

import Lesson3Pic1 from '../images/lessons/lumos/m3l3-1.png';
import Lesson3Pic2 from '../images/lessons/lumos/m3l3-2.png';
import Lesson3Pic3 from '../images/lessons/lumos/m3l3-3.png';
import Lesson3Pic4 from '../images/lessons/lumos/m3l3-4.png';
import Lesson3Pic5 from '../images/lessons/lumos/m3l3-5.png';
import Lesson3Pic6 from '../images/lessons/lumos/m3l3-6.png';
import Lesson3Pic7 from '../images/lessons/lumos/m3l3-7.png';
import Lesson3Pic8 from '../images/lessons/lumos/m3l3-8.png';
import Lesson3Pic9 from '../images/lessons/lumos/m3l3-9.png';
import Lesson3Pic10 from '../images/lessons/lumos/m3l3-10.png';
import Lesson3Pic11 from '../images/lessons/lumos/m3l3-11.png';
import Lesson3Pic12 from '../images/lessons/lumos/m3l3-12.png';
import Lesson3Pic13 from '../images/lessons/lumos/m3l3-13.png';
import Lesson3Pic14 from '../images/lessons/lumos/m3l3-14.png';
import Lesson3Pic15 from '../images/lessons/lumos/m3l3-15.png';
import Lesson3Pic16 from '../images/lessons/lumos/m3l3-16.png';
import Lesson3Pic17 from '../images/lessons/lumos/m3l3-17.png';
import Lesson3Pic18 from '../images/lessons/lumos/m3l3-18.png';
import Lesson3Pic19 from '../images/lessons/lumos/m3l3-19.png';
import Lesson3Pic20 from '../images/lessons/lumos/m3l3-20.png';
import Lesson3Pic21 from '../images/lessons/lumos/m3l3-21.png';
import Lesson3Pic22 from '../images/lessons/lumos/m3l3-22.png';
import Lesson3Pic23 from '../images/lessons/lumos/m3l3-23.png';
import Lesson3Pic24 from '../images/lessons/lumos/m3l3-24.png';
import Lesson3Pic25 from '../images/lessons/lumos/m3l3-25.png';
import Lesson3Pic26 from '../images/lessons/lumos/m3l3-26.png';
import Lesson3Pic27 from '../images/lessons/lumos/m3l3-27.png';
import Lesson3Pic28 from '../images/lessons/lumos/m3l3-28.png';
import Lesson3Pic29 from '../images/lessons/lumos/m3l3-29.png';
import Lesson3Pic30 from '../images/lessons/lumos/m3l3-30.png';
import Lesson3Pic31 from '../images/lessons/lumos/m3l3-31.png';

import Lesson4Pic1 from '../images/lessons/lumos/m3l4-1.png';
import Lesson4Pic2 from '../images/lessons/lumos/m3l4-2.png';
import Lesson4Pic3 from '../images/lessons/lumos/m3l4-3.png';
import Lesson4Pic4 from '../images/lessons/lumos/m3l4-4.png';
import Lesson4Pic5 from '../images/lessons/lumos/m3l4-5.png';

import Lesson5Pic1 from '../images/lessons/lumos/m3l5-1.png';
import Lesson5Pic2 from '../images/lessons/lumos/m3l5-2.png';
import Lesson5Pic3 from '../images/lessons/lumos/m3l5-3.png';
import Lesson5Pic4 from '../images/lessons/lumos/m3l5-4.png';
import Lesson5Pic5 from '../images/lessons/lumos/m3l5-5.png';
import Lesson5Pic6 from '../images/lessons/lumos/m3l5-6.png';
import Lesson5Pic71 from '../images/lessons/lumos/m3l5-7.0.png';
import Lesson5Pic72 from '../images/lessons/lumos/m3l5-7.1.png';
import Lesson5Pic73 from '../images/lessons/lumos/m3l5-7.2.png';
import Lesson5Pic8 from '../images/lessons/lumos/m3l5-8.png';
import Lesson5Pic9 from '../images/lessons/lumos/m3l5-9.png';
import Lesson5Pic10 from '../images/lessons/lumos/m3l5-10.png';
import Lesson5Pic11 from '../images/lessons/lumos/m3l5-11.png';
import Lesson5Pic121 from '../images/lessons/lumos/m3l5-12.0.png';
import Lesson5Pic122 from '../images/lessons/lumos/m3l5-12.1.png';
import Lesson5Pic13 from '../images/lessons/lumos/m3l5-13.png';
import Lesson5Pic141 from '../images/lessons/lumos/m3l5-14.0.png';
import Lesson5Pic142 from '../images/lessons/lumos/m3l5-14.1.png';
import Lesson5Pic151 from '../images/lessons/lumos/m3l5-15.0.png';
import Lesson5Pic152 from '../images/lessons/lumos/m3l5-15.1.png';
import Lesson5Pic161 from '../images/lessons/lumos/m3l5-16.1.png';
import Lesson5Pic162 from '../images/lessons/lumos/m3l5-16.2.png';
import Lesson5Pic17 from '../images/lessons/lumos/m3l5-17.png';
import Lesson5Pic18 from '../images/lessons/lumos/m3l5-18.png';
import Lesson5Pic191 from '../images/lessons/lumos/m3l5-19.1.png';
import Lesson5Pic192 from '../images/lessons/lumos/m3l5-19.2.png';
import Lesson5Pic193 from '../images/lessons/lumos/m3l5-19.3.png';
import Lesson5Pic20 from '../images/lessons/lumos/m3l5-20.png';
import Lesson5Pic211 from '../images/lessons/lumos/m3l5-21.png';
import Lesson5Pic212 from '../images/lessons/lumos/m3l5-21.0.png';
import Lesson5Pic213 from '../images/lessons/lumos/m3l5-21.1.png';
import Lesson5Pic214 from '../images/lessons/lumos/m3l5-21.2.png';
import Lesson5Pic22 from '../images/lessons/lumos/m3l5-24.png';
import Lesson5Pic23 from '../images/lessons/lumos/m3l5-25.png';
import Lesson5Pic24 from '../images/lessons/lumos/m3l5-26.png';

const data = {
    "Lesson1": {
        title: "Lesson 1 Creating Data Sources",
        lesson: [
            {
                outline: "Introduction to SQL Lab",
                content: (
                    <div>
                        <h2>
                            Lesson 1 Creating Data Sources <br />
                            <span>Introduction to SQL Lab</span>
                        </h2>
                        <p>
                            SQL Lab is a browser-based SQL editor integrated with Lumos. Currently, data in SQL Lab includes data mart, HDFS, and Parquet.
                        </p>
                        <img src={Lesson1Pic1} />
                        <p>
                            Next, we are going to go through how to use SQL Lab step-by-step.
                        </p>
                        <h5>
                            Step 1: After logging into Lumos, open SQL Lab to begin.
                        </h5>
                        <p>
                            Nested under the "Data" menu tab, click "New SQL Query" to navigate to the SQL editor page of SQL Lab.
                        </p>
                        <img src={Lesson1Pic2} />
                        <p>
                            In case you cannot access Lumos or fail to see the "Data" menu tab, please raise an “Apply Access” ticket at the Data Support Portal.
                        </p>
                        <h5>
                            Step 2: Choose Database from the dashboard menu.
                        </h5>
                        <p>
                            Choose Redshift[BI_country] to use data mart, Presto to use HDFS and Spark Playground to use Parquet.
                        </p>
                        <p className="lesson-topic__code">
                            💡 REMEMBER: You cannot execute queries across multiple databases.
                        </p>
                        <p>
                            However, you can query data spanning multiple schemas and tables within the same database.
                        </p>
                        <img src={Lesson1Pic3} />
                        <h5>
                            Step 3: Browse data by selecting a schema and table.
                        </h5>
                        <p>
                            After selecting a schema, you can search for a table by typing text directly into the table menu field.

                            After selecting a table, Lumos will automatically load and display a preview of its contents at the bottom right-hand corner.
                        </p>
                        <img src={Lesson1Pic4} />
                        <h5>
                            Step 4: Build and run a query to select the data you need.
                        </h5>
                        <p className="lesson-topic__code">
                            💡 REMEMBER: Save your queries as often you would save your work.
                        </p>
                        <p>
                            You can access your saved queries by clicking "Browse Queries" nested under the “Data” menu tab.
                        </p>
                        <img src={Lesson1Pic5} />
                        <h5>
                            Step 5: Download your results in CSV format or visualize.
                        </h5>
                        <p>
                            To create a live stream of data based on your query, you must instruct Lumos to create a data source from your SQL query.

                            To do this, click "Visualize" to configure the data source however you like, and hit save.

                            You can view a list of all your saved data sources by clicking "Browse Tables" nested under the "Data" menu tab.
                        </p>
                        <img src={Lesson1Pic6} />
                    </div>
                )
            },
            {
                outline: "Create Data Source",
                next: "/LumosUserGuide/Lesson2/1",
                content: (
                    <div>
                        <h2>
                            Lesson 1 Creating Data Sources <br />
                            <span>Create Data Source</span>
                        </h2>
                        <h4>Before you can create a data source:</h4>
                        <ul>
                            <li>Think about what kind of data you need to create a slice, and</li>
                            <li>Which database and table you can find the data.</li>
                        </ul>
                        <p>
                            {`For example, we can see a slice from the ID CM dashboard. It shows gross orders distribution over each subcategory and each date in the current week under the ‘Men Shoes’ category.

                            From the slice, we can easily see that the data we need is date, gross orders, subcategory, and main category which can be retrieved from tables: fact_placeorderdetail in order mart.`}
                        </p>
                        <img src={Lesson1Pic7} />
                        <h3>WITH Clause</h3>
                        <p>
                            You can use the WITH clause to create your data source. The WITH clause defines one or more subqueries where each subquery defines a temporary table. These temporary tables can be referenced in the FROM clause during the execution of the query. To do this:
                        </p>
                        <ol>
                            <li>Select useful raw data from the database.</li>
                            <li>Do the calculation based on the raw data selected from order mart.</li>
                            <li>Finally, select all the data from calculation results.</li>
                        </ol>
                        <img src={Lesson1Pic8} />
                        <p>
                            {`You can actually write one query to get the results that are exactly the same as the previous one using the WITH clause.
                            
                            But, if you need to create a data source whose data comes from multiple tables, then you are going to write a very long query. Often, it is always difficult to figure out the structure of subqueries and maintain such a big query.

                            In this situation, it is good to try it using the WITH clause, from raw data to calculation step by step. It will make your query structure clear and simpler to write and read.`}                        
                        </p>
                    </div>
                )
            }
        ]
    },
    "Lesson2": {
        title: "Lesson 2 Creating Charts and Visualizations",
        lesson: [
            {
                outline: "Enter visualization page - Method 1",
                content: (
                    <div>
                        <h2>
                            Lesson 2 Creating Charts and Visualizations <br />
                            <span>How to Enter to the Visualization Page</span>
                        </h2>
                        <h4>Enter visualization page - Method 1</h4>
                        <p>
                            After you run the query successfully in SQL Lab, you will see the results under the SQL command window.
                        </p>
                        <p>
                            Step 1: Click ‘Visualize’ to enter the data source setting page.
                        </p>
                        <img src={Lesson2Pic1} />
                        <p>
                            Step 2: Give a meaningful name on the top right text box.
                        </p>
                        <p>
                            Make sure ‘is_temporal’ is ticked for DATETIME columns, ‘sum’ is ticked for FLOAT and INTEGER columns and tick ‘groupable’, ‘filterable’ or ‘count_discount’ depending on whether you use that column for grouping and filtering. Last but not least, click ‘base16_encoded’ if there are non-English characters in that column
                        </p>
                        <p>
                            Step 3: Click ‘Visualize’ on bottom-left to enter data visualization page.
                        </p>
                        <img src={Lesson2Pic2} />
                    </div>
                )
            },
            {
                outline: "Enter visualization page - Method 2",
                next: "/LumosUserGuide/Lesson3/1",
                content: (
                    <div>
                        <h2>
                            Lesson 2 Creating Charts and Visualizations
                        </h2>
                        <h4>Enter visualization page - Method 2</h4>
                        <p>
                            After clicking ‘Visualize’, lumos will take us to the visualization page and create the data source in lumos automatically.
                        </p>
                        <p>
                            Step 1: Click ‘Browse Tables’ nested under the ‘Data’ menu tab.
                        </p>
                        <img src={Lesson2Pic3} />
                        <p>
                            Step 2: Click the table you just created to enter the data visualization page.
                        </p>
                        <img src={Lesson2Pic4} />
                        <h3>General introduction to Visualization Page</h3>
                        <h4>Visualization Page</h4>
                        <p>
                            Left panel is for the user to do some visualization settings, we will go through this in detail in the next section.
                        </p>
                        <img src={Lesson2Pic5} />
                        <p>
                            If you are done with the data pressentation, click ‘Save as’ on top left to save the slice or add it into a dashboard.
                        </p>
                        <img src={Lesson2Pic6} />
                        <p>
                            For data visualization settings, you may check <a href="https://docs.google.com/presentation/d/1TLz08jDL3W2SmQcO97YQds48NBwD2Z7_BqYxgd6hezo/edit?usp=sharing">here.</a>
                        </p>
                    </div>
                )
            }
        ]
    },
    "Lesson3": {
        title: "Lesson 3 Creating Dashboards and Reports",
        lesson: [
            {
                outline: "Dashboard Features",
                content: (
                    <div>
                        <h2>
                            Lesson 3 Creating Dashboards and Reports <br />
                            <span>Dashboard Features</span>
                        </h2>
                        <p>
                            When you open a dashboard, you can see a list of icons on the top right of the dashboard. This lesson will go through each icon’s function.
                        </p>
                        <h4>
                            Force Refresh Dashboard
                        </h4>
                        <p>
                            Any slice in a dashboard is generated by a data source.
                            Clicking this icon will force refresh all slices in this dashboard and display up-to-date data.
                        </p>
                        <img src={Lesson3Pic1} />
                        <h4>
                            Add Slices to Dashboard
                        </h4>
                        <p>
                            Clicking this icon lets you add slices to the current dashboard
                        </p>
                        <img src={Lesson3Pic2} />
                        <img src={Lesson3Pic3} />
                        <p>
                            The A region shows where you should type in the keyword to search for a slice.

                            The B region shows where you should tick the slices you want to add.

                            The C region points to the “Add Slices” button for you to finally add slices.
                        </p>
                        <h4>
                            Set Refresh Interval
                        </h4>
                        <p>
                            Clicking this icon lets you set the refresh interval of the current dashboard.
                        </p>
                        <img src={Lesson3Pic4} />
                        <img src={Lesson3Pic5} />
                        <h4>
                            Edit Dashboard
                        </h4>
                        <p>
                            Clicking this icon will allow you to edit the current dashboard.
                        </p>
                        <img src={Lesson3Pic6} />
                        <img src={Lesson3Pic7} />
                        <ul>
                            <li>Title: Rename the dashboard</li>
                            <li>Slices: Delete or add slices into dashboard</li>
                            <li>Owners: To add owner to dashboard, new added owner will also have the access to edit the dashboard</li>
                            <li>Guest: To add guest to dashboard, guests will only have the read access to dashboard</li>
                        </ul>
                        <p>
                            After making all these changes, do not forget to click the “Save” button at the bottom-left to save the changes.

                            There is an additional way to enter the “Edit Dashboard” page.
                        </p>
                        <ul>
                            <li>
                                Step 1: Click “Browse Dashboards” under “Dashboard” menu tab
                            </li>
                            <li>
                                Step 2: Locate the dashboard you want to edit and click the middle icon before the dashboard
                            </li>
                        </ul>
                        <img src={Lesson3Pic8} />
                        <h4>
                            Import Dashboard and Sent Email
                        </h4>
                        <p>
                            Clicking this icon allows you to import the whole dashboard as images and send email to recipients.
                        </p>
                        <img src={Lesson3Pic9} />
                        <img src={Lesson3Pic10} />
                        <h4>
                            Save Dashboard
                        </h4>
                        <p>
                            Clicking this icon allows you to overwrite or make a copy of the current dashboard
                        </p>
                        <img src={Lesson3Pic11} />
                        <p className="lesson-topic__code">
                            💡 REMEMBER: (1) Overwrite the dashboard when you make changes in the current dashboard like moving or deleting the slices. (2) You can find the copy of the current dashboard by clicking “Browse Dashboards” under the “Dashboard” menu tab.
                        </p>
                    </div>
                )
            },
            {
                outline: "Slice Features",
                content: (
                    <div>
                        <h2>
                            Lesson 3 Creating Dashboards and Reports <br />
                            <span>Slice Features</span>
                        </h2>
                        <p>
                            There is also a set of icons for each slice. We will be exploring these in this section.
                        </p>
                        <img src={Lesson3Pic12} />
                        <h4>
                            Move Chart
                        </h4>
                        <p>
                            Clicking and holding this icon allows you to move the slice.
                        </p>
                        <img src={Lesson3Pic13} />
                        <h4>
                            Refresh Slice
                        </h4>
                        <p>
                            Clicking this icon allows you to force refresh the slice to display up-to-date data.
                        </p>
                        <img src={Lesson3Pic14} />
                        <img src={Lesson3Pic15} />
                        <h4>
                            Edit Slice
                        </h4>
                        <p>
                            Clicking this icon allows you to edit the slice details.
                        </p>
                        <img src={Lesson3Pic16} />
                        <ul>
                            <li>Name: Rename the dashboard</li>
                            <li>Owners: This portion allows you to add an owner to the dashboard; the new added
                                owner
                                will also have the access to edit the dashboard.</li>
                        </ul>
                        <img src={Lesson3Pic17} />
                        <p>
                            You can use another way to enter the “Edit Slice” page.
                        </p>
                        <ul>
                            <li>Step 1: Click “Browse Slices” under the “Slices” menu tab.</li>
                            <li>Step 2: Locate the slice you want to edit and click the middle icon before the
                                slice.
                            </li>
                        </ul>
                        <img src={Lesson3Pic18} />
                        <h4>
                            Explore Slice
                        </h4>
                        <p>
                            Clicking this icon allows you to enter the visualization setting page.
                        </p>
                        <img src={Lesson3Pic19} />
                        <img src={Lesson3Pic20} />
                        <h4>
                            Delete Slice
                        </h4>
                        <p>
                            Clicking this icon allows you to delete the visualization setting page.
                        </p>
                        <img src={Lesson3Pic21} />
                        <img src={Lesson3Pic22} />
                        <h4>
                            Export as CSV
                        </h4>
                        <p>
                            Clicking this icon allows you to export the raw data used in the current slice as a csv file.
                        </p>
                        <img src={Lesson3Pic23} />
                        <img src={Lesson3Pic24} />
                        <p className="lesson-topic__code">
                            💡 REMEMBER: All formatting in the slice will disappear in csv file.
                        </p>
                        <h4>
                            Export as Excel File
                        </h4>
                        <p>
                            Clicking this icon allows you to download data as an Excel file and formatting will be kept in the Excel file.
                        </p>
                        <img src={Lesson3Pic25} />
                        <img src={Lesson3Pic26} />
                        <p className="lesson-topic__code">
                            💡 REMEMBER: Only the table view and pivot table chart have this icon.
                        </p>
                    </div>
                )
            },
            {
                outline: "How to Design a Dashboard",
                next: "/LumosUserGuide/Lesson4/1",
                content: (
                    <div>
                        <h2>
                            Lesson 3 Creating Dashboards and Reports <br />
                            <span>How to Design a Dashboard</span>
                        </h2>
                        <h4>
                            Name Slices
                        </h4>
                        <p>
                            Give a meaningful name to each slice in the dashboard. Guests should be able to tell what information this slice contains through reading the slice name.
                            
                            From the featured slice, we can easily tell this slice compares the daily orders with the daily order target.
                        </p>
                        <img src={Lesson3Pic27} />
                        <h4>
                            Use Separator Visualization
                        </h4>
                        <p>
                            Divide all slices into different sections and display the general information of each section using separator visualization.
                        </p>
                        <img src={Lesson3Pic28} />
                        <p>
                            If there are many calculations or abbreviations in one section, you should list out formulas or definitions of each metric.
                        </p>
                        <img src={Lesson3Pic29} />
                        <h4>
                            Cluster Related Slices
                        </h4>
                        <p>
                            Put related slices together or into one cluster under each section. On the given slice, we put order-related slices on the left part. GMV related slices are in the middle and rebate-related slices are on the right.
                        </p>
                        <img src={Lesson3Pic30} />
                        <h4>
                            Cluster Related Slices
                        </h4>
                        <p>
                            As we know, the filter box only applies for slices sharing the same data source. In this way, a filter box should be placed around the affected slices.
                        </p>
                        <img src={Lesson3Pic31} />
                    </div>
                )
            }
        ]
    },
    "Lesson4": {
        title: "Lesson 4 Introduction to Presto DB",
        lesson: [
            {
                outline: "Introduction to Presto DB",
                next: "/LumosUserGuide/Lesson5/1",
                content: (
                    <div>
                        <h2>Lesson 4 Introduction to PrestoDB</h2>
                        <div id="list-topic1">

                            <p>Presto is a new database soon-to-be opened to all BI roles at Shopee.
                                For those familiar with Cargo, Presto contains all the tables you already have access
                                to.
                                Presto has shown to perform queries faster and more reliably than Cargo. Furthermore,
                                Presto
                                is fully integrated with Lumos, meaning you can produce visualizations and dashboards
                                directly from your queries without switching applications.<br /> <br />
                                This presentation shows how you can interact with Presto using Lumos’ SQL Lab - a
                                browser-based SQL editor similar to Cargo in function.
                                Note: Presto, Redshift, and Spark have different SQL syntax and semantics.
                                View Jerry Leo’s guide to converting Spark queries to Presto here.
                            </p>
                            <h4>Step 1: After logging into Lumos, open SQL Lab to begin.</h4>
                            <p>Nested under the ‘Data’ menu tab, click ‘New SQL Query’ to navigate to the SQL editor
                                page of
                                SQL Lab. <br />
                                In case you cannot access Lumos, or fail to see the ‘Data’ menu tab, please contact me
                                at: anthony.ho@shopee.com
                            </p>
                            <img src={Lesson4Pic1} />
                                <br />
                            <h4>Step 2: Choose Presto from the dashboard menu.</h4>
                            <p>Please note you cannot execute queries across multiple databases. <br />
                                However, you can query data spanning multiple schemas and tables within the same
                                database.
                            </p>
                            <img src={Lesson4Pic2} />
                            <br />
                            <h4>Step 3: Browse data by selecting a schema and table.</h4>
                            <p>After selecting a schema, you can search for a table by typing text directly into the
                                table
                                menu field. <br />
                                After selecting a table, Lumos will automatically load and display a preview of its
                                contents
                                at the bottom right-hand corner. </p>
                            <img src={Lesson4Pic3} />
                            <br />
                            <h4>Step 4: Build and run a query to select the data you need.</h4>
                            <p>You can access your saved queries by clicking ‘Browse Queries’ nested under the ‘Data’
                                menu
                                tab. <br />
                                If your query returns a syntax error even though it can run successfully in Cargo, refer
                                to
                                this document.
                            </p>
                            <img src={Lesson4Pic4} />
                                <br />
                            <h4>Step 5: Download your results in CSV format, or visualize.</h4>
                            <p>To create a live stream of data based on your query, you must instruct Lumos to create a
                                data
                                source from your SQL query. </p>
                            <p>To do this, click ‘Visualize’, configure the data source however you like, and hit save.
                            </p>
                            <p>You can view a list of all your saved data sources by clicking ‘Browse Tables’ nested
                                under
                                the ‘Data’ menu tab.</p>

                            <img src={Lesson4Pic5} />
                                <br />
                        </div>
                    </div>
                )
            }
        ]
    },
    "Lesson5": {
        title: "Lesson 5 Useful Tips",
        lesson: [
            {
                outline: "Data Source Manipulation",
                content: (
                    <div>
                        <h3>Lesson 5 Useful Tips</h3>
                        <div id="list-topic1">
                            <h4>Data Source Manipulation</h4>
                            <h5>Add SQL Metric</h5>
                            <p><b>INTUITION:</b> Let’s say we have daily orders, gmv data, and calculated daily average basket
                                size
                                (abs) using formula = orders/gmv as follows. It works fine for daily charts.</p>
                            <p id="remember">💡 What if we need ABS on a weekly or monthly basis using the same data source?</p>
                            <p>We need a calculation formula like sum(gmv)/sum(gross_orders) as ABS in the data source
                                SQL
                                so that it will be calculated every time we run the query for a different time span.</p>
                            <img src={Lesson5Pic1} />
                            <br />
                            <h6>Step 1: Select Data &gt; Browse Tables in the Lumos Homepage</h6>
                            <br />
                            <img src={Lesson5Pic2} />
                            <br />
                            <h6>Step 2: Select the data source that you want to edit and click ‘Edit Record’</h6>
                            <br />
                            <img src={Lesson5Pic3} />
                            <br />
                            <h6>Step 3: Choose ‘List Sql Metric’ panel to top</h6>
                            <br />
                            <img src={Lesson5Pic4} />
                            <br />
                            <h6>Step 4: Scroll down to the end and you will see the column list of this data source</h6>
                            <br />
                            <h6>Step 5: Click the ‘+’ sign to ‘Add a new record’</h6>
                            <br />
                            <img src={Lesson5Pic5} />
                            <br />
                            <h6>Step 6: Fill in the necessary fields in ‘Add Sql Metric’</h6>
                            <ul>
                                <li>Metric</li>
                                <li>Verbose Name</li>
                                <li>SQL Expression</li>
                                <li>Table</li>
                            </ul>
                            <h6>Click 'Save'</h6>
                            <img src={Lesson5Pic6} />

                        </div>
                    </div>
                )
            },
            {
                outline: "Data Visualization Settings",
                last: true,
                content: (
                    <div>
                        <h3>Lesson 5 Useful Tips</h3>
                        <div id="list-topic1">
                            <h4>Data Visualization Settings</h4>
                            <h5>Separator</h5>
                            <ul>
                                <li><b>With Word Description</b></li>
                                <ul>
                                    <li>Separate Charts into Sections</li>
                                    <li>Metrics Explanation</li>
                                    <li>Instructions for Users</li>
                                </ul>
                            </ul>
                            <img src={Lesson5Pic71} />
                            <img src={Lesson5Pic72} />
                            <img src={Lesson5Pic73} />
                            <h6>Settings to Adjust:</h6>
                            <ul>
                                <li>Visualization Type</li>
                                <img src={Lesson5Pic8} />
                                <li id="code-format">Code</li>
                                <ul>
                                    <li id="code-format">------ : underline</li>
                                    <li id="code-format">
                                        &lt; br&gt; : new line
                                    </li>
                                    <li># : header</li>
                                    <li>**: bold</li>
                                    <li>* /+ /- :bullet</li>
                                    <li>More explanations <a href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet">here</a>.
                                    </li>
                                    <p>--------------- <br />
                                        &lt; br&gt; <br />
                                            #####**Sales Total and Transaction Number Total** <br />
                                            ###### - Breakdown details of Sales Total and Transaction Number Total can
                                            be
                                            found in the below table <br />
                                            ###### - C30 Users: Number of users who have at least one transaction from
                                            59
                                            days ago to 30 days ago, but do not have transactions from 29 days ago up to
                                            reporting day <br />
                                            &lt; br&gt; <br />
                                                --------------- <br />
                                    </p>
                                    <img src={Lesson5Pic9}/>
                                </ul>
                            </ul>
                            <br />
                            <h5>Time Series - Line Chart (Multiple lines)</h5>
                            <h6>With Multiple Lines for Comparison</h6>
                            <img src={Lesson5Pic10} />
                            <p>Comparison among categories/buckets - This is used to keep track of the performance among
                                categories/buckets.</p>
                            <img src={Lesson5Pic11} />
                            <h6>Settings to Adjust:</h6>
                            <ul>
                                <li>Visualization Type</li>
                                <img src={Lesson5Pic121} />
                                <li>Metrics</li>
                                <li>Group By</li>
                                <img src={Lesson5Pic122} />
                            </ul>

                            <h6>Tips on Data Source:</h6>
                            <p>Use case when in SQL to define bucket or rename the category, e.g.</p>
                            <p class="code-format">case itemid <br />
                                when 726124778 then '75 Shell' <br />
                                when 726123824 then '135 Shell' <br />
                                else 'Non-Shell' <br />
                                end as Shell_name <br />
                                or <br />
                                case <br />
                                when discount_pct&gt;0.5 then '&gt;50%' <br />
                                when discount_pct&gt;0.3 then '&gt;30-50%' <br />
                                when discount_pct&gt;0.1 then '&gt;10-30%' <br />
                                when discount_pct&gt;0.0 then '&gt;0-10%' <br />
                                else '0%' <br />
                                end as Discount_Bucket <br />
                            </p>
                            <img src={Lesson5Pic13} />
                            <p>To select a particular category(s) out of the ocean:</p>
                            <img src={Lesson5Pic141} />
                            <p>Double click on one of the waves you would like to particularly see.</p>
                            <img src={Lesson5Pic142} />
                            <h5>Time Series - Line Chart (Multiple lines)</h5>

                            <h6>Static Target/Budget/Average v.s. Performance</h6>
                            <p>This is used to monitor for unexpected dips and peaks generally see how much a metric is
                                under- or over-performing.</p>
                            <img src={Lesson5Pic151} />
                            <img src={Lesson5Pic152} />
                            <br />
                            <h6>For Static Target/Budget: Settings to Adjust</h6>
                            <ul>
                                <li>Visualization Type</li>
                                <img src={Lesson5Pic161} />
                                <li>Metrics</li>
                                <img src={Lesson5Pic162} />
                            </ul>
                            <h6>Tips on Data Source:</h6>
                            <p>Use Google Ingestion Tool to store monthly budget/target as a table in Presto, then you
                                are
                                able to join in SQL to get the figure.</p>
                            <p>Keep track can be done on daily basis or month to date basis. </p>
                            <img src={Lesson5Pic17} />
                            <img src={Lesson5Pic18} />
                            <h6>For Average vs. Performance: Settings to Adjust</h6>
                            <ul>
                                <li>Visualization Type</li>
                                <img src={Lesson5Pic191} />
                                <li>Metrics</li>
                                <img src={Lesson5Pic192} />
                                <li>Chart options (optional)</li>
                                <ul>
                                    <li>Show Markers</li>
                                    <li>Line Style</li>
                                </ul>
                                <img src={Lesson5Pic193} />
                                <img src={Lesson5Pic20} />
                                <h6>Performance Over Time</h6>
                                <p>This is used to compare recent data with historical data and gauge whether current
                                    performance is better or worse than before, and by how much.</p>
                                <img src={Lesson5Pic211} />
                                <h6>Settings to Adjust:</h6>
                                <ul>
                                    <li>Visualization Type</li>
                                    <img src={Lesson5Pic212} />
                                    <li>Metrics</li>
                                    <img src={Lesson5Pic213} />
                                    <li>Chart Options (Range Filter)</li>
                                    <img src={Lesson5Pic214} />
                                </ul>
                                <h6>Tips on Data Source:</h6>
                                <p>Use date_add/date_trunc function in SQL for different time span, e.g.</p>
                                <ul>
                                    <li>D-7</li>
                                    <p class="code-format">date_add('day', -7, grass_date) as D-7
                                        e.g. ‘2018-08-15’ --&gt; ‘2018-08-08’
                                    </p>
                                    <li>Weekly data</li>
                                    <p class="code-format">date_trunc('week',grass_date)
                                    </p>
                                    <p>e.g. ‘2018-08-15’ --&gt; ‘2018-08-13’ (Monday of the week)</p>
                                    <li>First Day of Last Month:</li>
                                    <p class="code-format">date_add('month',-1, date_trunc('month',grass_date))
                                    </p>
                                    <p>e.g. ‘2018-08-15’ --&gt; ‘2018-08-01’ --&gt;‘2018-07-01’
                                    </p>
                                    <li>More explanations <a href="https://prestodb.io/docs/current/functions/datetime.html " target="_blank"><b>here</b></a>.
                                    </li>
                                </ul>
                                <h5>Markup</h5>
                                <ul>
                                    <li>With Links to Other Dashboards</li>
                                    <ul>
                                        <li>Direct to other relevant dashboards</li>
                                        <li>More structured</li>
                                        <li>Avoid overloaded</li>
                                    </ul>
                                </ul>
                                <img src={Lesson5Pic22} />
                                <h6>Settings to Adjust:</h6>
                                <ul>
                                    <li>Visualization Type</li>
                                    <li>Markup Type</li>
                                    <li>Code</li>
                                    <ul>
                                        <li>Markup Dashboard Link Sample:</li>
                                        <p class="code-format">#### &lt; a href="http://Dashboard_URL"&gt; Dashboard_Name
                                                &lt; /a&gt; E.g. #### &lt; a
                                                    href="http://lumos.idata.shopee.com/superset/dashboard/593/"&gt;
                                                    Category Performance
                                                    &lt; /a&gt; </p> <img src={Lesson5Pic23} />
                                                    <br /><img src={Lesson5Pic24} />
                                    </ul>
                                </ul>
                        </ul></div>
                    </div>
                )
            }
        ]
    }
}

export default data;