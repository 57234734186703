import { useEffect, useState, useContext } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { ThreeDots } from 'react-loading-icons'
import { Button, Form, Table } from 'react-bootstrap';
import BannerHeader from "../assets/images/playground/laptop.png"
import Subheader from "./reusables/Subheader";
import Lessons from "../assets/lessons/sql";
import axios from 'axios';
import SButton from './reusables/SButton';
import AppContext from "./reusables/AppContext";

const Playground = () => {
    const { user } = useContext(AppContext);
    const params = useParams();
    const location = useLocation();

    const [query, setQuery] = useState('');
    const [output, setOutput] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    const url = process.env.REACT_APP_API_ENDPOINT;
    const config = {
        headers: {
            idToken: user?.idToken
        }
    }

    const handleQuery = () => {
        setLoading(true);
        axios.post(`${url}/bigQuery`, {
            query
        }, config).then(res => {
            setOutput(res.data);
            setError(null);
            setLoading(false);
        }).catch(err => {
            setError(err.response.data.message)
            setOutput(null)
            setLoading(false);
        });
    }

    const renderOutput = (data) => {
        let toReturn = <i>Nothing to Display</i>;
        if (data && data.length > 0) {
            const headers = Object.keys(data[0]);
            toReturn = (
                <div>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th></th>
                                {
                                    headers.map((header) => {
                                        return <th>{header}</th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map((dat, index) => {
                                    return (
                                        <tr>
                                            <th>
                                                { index }
                                            </th>
                                            {
                                                headers.map((header) => {
                                                    return (
                                                        <td>
                                                            {(dat[header] && typeof dat[header] === 'object') ?
                                                                dat[header].value 
                                                                : dat[header]
                                                            }
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            )
        }
        return toReturn;
    }

    const renderTextAreaScore = () => {
        return  (
            <>
                <Form.Control
                    as="textarea" 
                    placeholder="Enter query here"
                    onChange={({target}) => {
                        setQuery(target.value)
                    }}
                    disabled={loading}
                />
                <div>
                    <SButton
                        className="view-output"
                        onClick={handleQuery}
                        disabled={loading}
                    >
                        View Output
                    </SButton>
                </div>
                <div>
                    <h3>Receiving Errors?</h3>
                    <ul>
                        <li>
                            <b>When using CAST(),</b> you can only convert it for now into BOOL, INT64, NUMERIC, BIGNUMERIC, FLOAT64, STRING, BYTES, DATE, DATETIME, TIME, or TIMESTAMP.
                        </li>
                        <li>
                            <b>When selecting all columns,</b> you can limit the rows to be produced by the query.
                        </li>
                    </ul>
                </div>
            </>
        )
    }

    return (
        <div className="playground">
            <Subheader
                header="Training Ground"
                body={
                    <div>
                        <p>Try practicing your query skills by using the tables: user_mart, item_mart, and order_mart.</p>
                        <p>
                        💡 Tip: On actual queries you'll be working on in the future, you'll be referencing different tables from different datasets. For this time, append sql-training-portal.module_dataset_1. before the table name for the query to work. For an example query: "SELECT * FROM sql-training-portal.module_dataset_1.order_mart LIMIT 1"
                        </p>
                    </div>
                }
                image={BannerHeader}
            />
            <div className="playground__body">
                <div className="playground__body__test">
                    <div className="quiz__body__test__textarea">
                        { renderTextAreaScore() }
                    </div>
                    <div className="playground__body__test__output">
                        <h2>Your Output</h2>
                        { loading ? 
                            (<div style={{textAlign: 'center'}}><ThreeDots fill="#EE4D2D" /></div>) 
                            : (error || renderOutput(output)) 
                        }
                    </div>
                </div>
                <div className="playground__body__footer">
                    <h2>Tables</h2>
                    <div>
                        <div>
                            <h3>user_mart</h3>
                            <p>Commonly Used Columns: user_id, shop_id, registration_datetime, is_email_verified, is_phone_verified, is_seller, status</p>
                        </div>
                        <div>
                            <h3>item_mart</h3>
                            <p>Commonly Used Columns: user_id, shop_id, registration_datetime, is_email_verified, is_phone_verified, is_seller, status</p>
                        </div>
                        <div>
                            <h3>order_mart</h3>
                            <p>Commonly Used Columns: user_id, shop_id, registration_datetime, is_email_verified, is_phone_verified, is_seller, status</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Playground;