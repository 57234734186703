import { useEffect, useRef, useContext } from 'react';
import Subheader from "./reusables/Subheader";

import ProgressBanner from "../assets/images/progress/progress_banner.jpg";
import LessonList from "./reusables/LessonList";

import lessonSql from "../assets/lessons/sql";
import lessonLumos from "../assets/lessons/lumos";
import lessonPresto from "../assets/lessons/presto";
import lessonPrestoDocu from "../assets/lessons/prestoDocu";

import AppContext from "./reusables/AppContext";

const Progress = () => {
    const { user, updateUserProgress } = useContext(AppContext);
    const counter = useRef(0);

    useEffect(() => {
        if (user && counter.current === 0) {
            counter.current += 1;
            updateUserProgress(user.uid)
        }
    }, [user]);

    return(
        <section className="myProgress">
            <Subheader 
                header={ <span>View My Progress</span> }
                body = {
                    <div>
                        <p>Let's see how well you have been progressing over our training modules.</p>
                        <p>Continue making progress by clicking on available lessons per module. Happy learning!</p>
                    </div>
                }
                image = { ProgressBanner }
            />
            <LessonList 
                listHeader="SQL Training"
                hideBookPic
                lessons={lessonSql}
                sqlTraining
                lessonPlan="SQLTraining"
                quizesProgress={user?.['quiz-progress']}
                pageProgress={user?.['progress-pages']}
            />
            <LessonList 
                listHeader="Lumos User Guide"
                hideBookPic
                lessons={lessonLumos}
                lessonPlan="LumosUserGuide"
                pageProgress={user?.['progress-pages']}
            />
            <LessonList 
                listHeader="Presto SQL Optimization"
                hideBookPic
                lessons={lessonPresto}
                lessonPlan="PrestoSQLOptimization"
                pageProgress={user?.['progress-pages']}
            />
            <LessonList 
                listHeader="Presto Documentation"
                hideBookPic
                lessons={lessonPrestoDocu}
                lessonPlan="PrestoDocumentation"
                pageProgress={user?.['progress-pages']}
            />
        </section>
    );
}

export default Progress;