import { useEffect, useContext, useState, useRef } from "react";
import { Routes, Route, useLocation  } from "react-router-dom";
import { getDatabase, ref, get, child } from "firebase/database";
import { ThreeDots } from 'react-loading-icons'
import { 
  getAuth,
  onAuthStateChanged 
} from "firebase/auth";

import firebase from './assets/firebase';
import Login from './components/Login';
import SignUp from './components/SignUp';
import ForgetPassword from './components/ForgetPassword';
import Footer from './components/Footer';
import Dashboard from "./components/Dashboard";
import Navigation from "./components/Navigation";
import About from "./components/About";
import Progress from "./components/Progress";
import Quiz from "./components/Quiz";
import Playground from "./components/Playground";

import PageNotFound from "./components/reusables/PageNotFound";
import AppContext from "./components/reusables/AppContext";
import PrestoLesson from "./components/lessons/PrestoLesson";
import PrestoLessons from "./components/lessons/PrestoLessons";
import LumosLessons from "./components/lessons/LumosLessons";
import LumosLesson from "./components/lessons/LumosLesson";
import SQLLessons from "./components/lessons/SQLLessons";
import SQLLesson from "./components/lessons/SQLLesson";
import PrestoDocu from "./components/lessons/PrestoDocu";

const AuthProvider = ({ children, fetchingUser }) => {
  let toReturn;
  const { user } = useContext(AppContext);
  const location = useLocation();
  const unAuthRoutes = [
    '/signup',
    '/reset',
    '/login'
  ]

  if (user || unAuthRoutes.includes(location.pathname)) {
    toReturn = children;
  } else if (!unAuthRoutes.includes(location.pathname) && fetchingUser) {
    toReturn = (
      <div className="loading-page">
        <ThreeDots fill="#EE4D2D" />
      </div>
    )
  } else {
    toReturn = <Login />
  }

  return toReturn
}

const Router = () => {
  const [user, setUser] = useState(null);
  const [fetchingUser, setFetchingUser] = useState(true);
  
  const database = getDatabase(firebase);
  const auth = getAuth(firebase);
  const location = useLocation();

  const setRefresh = useRef(null); 
  const userRef = useRef(null);

  const updateUserProgress = (uid) => {
    get(child(ref(database), `users/${uid}`)).then((snapshot) => { 
      if (snapshot.exists()) {
        const value = snapshot.val();
        let clonedUser = { ...user };
        clonedUser.progress = value.progress;
        clonedUser['progress-pages'] = value['progress-pages'];
        clonedUser.queries = value.queries;
        clonedUser['queries-scored'] = value['queries-scored'];
        clonedUser['quiz-progress'] = value['quiz-progress'];
        console.log(clonedUser)
        setUser(clonedUser);
      } else {
        console.log("No data available");
      }
    })
  }
  
  useEffect(() => {
    window.scrollTo(0,0);
  }, [location.pathname]);

  useEffect(() => {
    setTimeout(() => {
      // if cannot fetch user by 2.5s, require login
      setFetchingUser(false);
    }, 2500);

    // recommended way to fetch on first load
    onAuthStateChanged(auth, user => {
      if (user) {
        const { uid } = user;
        get(child(ref(database), `users/${uid}`)).then((snapshot) => { 
          if (snapshot.exists()) {
            user.getIdToken().then((idToken) => {
              setFetchingUser(false);
              setUser({
                ...user,
                ...snapshot.val(),
                idToken
              });

              // if any interval exists stop it before loading new interval
              if (setRefresh.current) {
                clearInterval(setRefresh.current);
              }
              userRef.current = user;
              // refresh every 5 mins when logged in
              setRefresh.current = setInterval(() => {
                if (userRef.current) {
                  console.log('Token Refreshed')
                  userRef.current.getIdToken(true); // token refreshed
                }
              }, 50000);            
            });
          } else {
              console.log("No data available");
          }
        }).catch((error) => {
          console.log('Signed out')
          console.error(error);
        });
      }
    })

    return () => {
      if (setRefresh.current) {
        clearInterval(setRefresh.current);
      }
    }
  }, [])

  return (
    <div className="sqlTraining">
        <AppContext.Provider value={{
          user,
          updateUserProgress
        }}>
          <AuthProvider 
            fetchingUser={fetchingUser}
          >
            <Navigation />
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/reset" element={<ForgetPassword />} />
                <Route path="/about" element={<About />} />
                <Route path="/progress" element={<Progress />} />
                <Route path="/home" element={<Dashboard />} />
                <Route path="/playground" element={<Playground />} />
                
                <Route path="/SQLTraining" element={<SQLLessons />} />
                <Route path="/SQLTraining/:lessonKey/:id" element={<SQLLesson />} /> {/* should i have nested? */}
                <Route path="/SQLTraining/quiz/:lessonKey/:id" element={<Quiz />} />
                
                <Route path="/PrestoSQLOptimization" element={<PrestoLessons />} />
                <Route path="/PrestoSQLOptimization/:lessonKey/:id" element={<PrestoLesson />} />
                
                <Route path="/LumosUserGuide" element={<LumosLessons />} />
                <Route path="/LumosUserGuide/:lessonKey/:id" element={<LumosLesson />} />
                
                <Route path="/PrestoDocumentation/Lesson1/1" element={<PrestoDocu />} />
                
                <Route path="*" element={<PageNotFound />} />
            </Routes>
          </AuthProvider>
          <Footer />
        </AppContext.Provider>
    </div>
  );
}

export default Router;
