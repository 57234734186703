import { useContext } from 'react';
import Subheader from '../reusables/Subheader';
import LessonList from '../reusables/LessonList';
import lessons from '../../assets/lessons/lumos';

import AppContext from "../reusables/AppContext";

const LumosLessons = () => {
    const { user } = useContext(AppContext);
    return (
        <div className="lumosLessons">
            <Subheader 
                header="Lumos User Guide"
                body={
                    <div>
                        <p>5 lessons</p>
                        <p>Learn how to do basic SQL queries and create dashboards & reports on Lumos.</p>
                    </div>
                }
            />
            <LessonList 
              lessons={lessons}
              lessonPlan="LumosUserGuide"
              quizesProgress={user?.['quiz-progress']}
              pageProgress={user?.['progress-pages']}
            />
        </div>
    )
}

export default LumosLessons;