import Image from '../../assets/images/error/error.png';

const PageNotFound = () => {
    return (
        <div className="pageNotFound">
            <h1>404</h1>
            <h3>Not Found</h3>
            <h4>Oops! Page not found.</h4>
            <img src={Image} />
        </div>
    )
}

export default PageNotFound;