import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Subheader from "./reusables/Subheader";
import Info from "./reusables/Info";

import BannerHeader from "./../assets/images/dashboard/banner_header.png"

const About = () => {
    return (
        <section className="about">
                <Subheader
                    header={<span> Welcome to the Shopee Query Training Portal. </span>}
                    body={
                        <div>
                            <p>Learn how to create SQL queries using the PrestoSQL Syntax and how to use Lumos to get your needed data.</p>
                            <p>Pick a module to explore today</p>
                            <Link to='/home'>
                                <Button size="lg" className="about__button">See Modules</Button>
                            </Link>
                        </div>
                    }
                    image={BannerHeader}
                />
                <Info />
                <h4>Read the user guide <a href='https://docs.google.com/document/d/1qfH_JyjzE38X7Lc2Hpul77vKSZm7wKVtlTYtQceVNlY/edit' target="_blank"> here</a>.</h4>
        </section>
    );
}

export default About;