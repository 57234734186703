import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Button from './reusables/SButton';
import { 
    getAuth,
    createUserWithEmailAndPassword,
} from "firebase/auth";
import axios from 'axios';
import { ThreeDots } from 'react-loading-icons'

import Shopee from '../assets/images/logos/shopee_landing.png';
import Landing from '../assets/images/signup/registration.jpg';

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');

    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [accepted, setAccepted] = useState(false);

    const createProfile = (user) => {
        const { accessToken: idToken } = user;
        const config = {
            headers: {
                idToken
            }
        }
        const endpoint = process.env.REACT_APP_API_ENDPOINT;
        axios.post(`${endpoint}/user`, {
            email: user.email,
            name
        }, config).then(() => {
            setLoading(false);
            console.log('redirecting')
            window.location.href="/"
        })
    }

    const handleSignUp = () => {
        setLoading(true);
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            createProfile(user);
            console.log(user)
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorMessage);
            alert(errorMessage);
            setLoading(false);
        });
    }

    const isError = (
        !(email.includes('@shopee') || email.includes('@seabank'))
        || name === ''
        || password.length < 6
        || !accepted
    )

    return (
        <div className="signUp">
            <section className="signUp__header">
                <div className="signUp__header__text">
                    <h2>Let's start your journey</h2>
                    <img src={Landing} />
                </div>
                <div className="signUp__header__form">
                    <img src={Shopee} />
                    <h2>Query Training Portal</h2>
                    <h3>Sign Up</h3>
                    <Form.Group controlId="signupEmail">
                        <Form.Label>
                            Email address
                            <span className="required"> * </span>
                            <span className="sidenote-label">Must use Shopee Email</span>
                        </Form.Label>
                        <Form.Control 
                            type="email" 
                            placeholder="jackie.chan@shopee.com" 
                            onChange={({target}) => {setEmail(target.value)}}
                        />
                    </Form.Group>
                    <Form.Group controlId="signupEmail">
                        <Form.Label>
                            Name
                            <span className="required"> * </span>
                        </Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Jackie Chan" 
                            onChange={({target}) => {setName(target.value)}}
                        />
                    </Form.Group>
                    <Form.Group controlId="signupPassword">
                        <Form.Label>
                            Password 
                            <span className="required"> * </span>
                            <span className="sidenote-label">Must be at least 6 characters long</span>
                        </Form.Label>
                        <Form.Control 
                            type={showPassword ? "text" : "password"}
                            placeholder="Your password" 
                            onChange={({target}) => {setPassword(target.value)}}
                        />
                       <Form.Check
                            type="checkbox" 
                            label="Show Password" 
                            checked={showPassword}
                            onChange={() => {
                                setShowPassword(!showPassword)
                            }}
                        />
                    </Form.Group>
                    <p>
                        When you sign-up through this form, we will collect and process your personal information for the purposes of registration and log in. This is in accordance with the Data Privacy Act of 2012 (R.A. 10173) and <a target="_blank" href="https://shopee.ph/docs/3591">Shopee’s Privacy Policy</a>
                    </p>
                    <Form.Group controlId="signUpCheckbox">
                        <Form.Check
                            type="checkbox"
                            label="I certify that I have read and accepted the above terms and conditions." 
                            checked={accepted}
                            onChange={() => {
                                setAccepted(!accepted)
                            }}
                        />
                    </Form.Group>
                    <Button 
                        className="signUp__register"
                        onClick={handleSignUp}
                        disabled={loading || isError}
                    >
                        { loading 
                            ? <ThreeDots fill="white" /> 
                            : "Register" 
                        }
                    </Button>
                    <Link to="/">Back to Login</Link>
                </div>
            </section>
        </div>
    );
}

export default SignUp;