import { useState } from 'react';
import Info from './reusables/Info';
import { Form } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import app from '../assets/firebase';
import Button from './reusables/SButton';
import { ThreeDots } from 'react-loading-icons'
import EmbededVideo from './reusables/EmbededVideo';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import Shopee from '../assets/images/logos/shopee_landing.png';
import Landing from '../assets/images/login/landing.png';

const Login = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleSignIn = () => {
        setLoading(true);
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
        .then(() => {
            // setuser done in router
            if (location.pathname.includes('login')) {
                navigate('/')
            }
        })
        .catch((error) => {
            const { code, message } = error;
            alert(message);
            setLoading(false);
        });
    }

    return (
        <div className="login">
            <section className="login__header">
                <div className="login__header__text">
                    <h2>Come and make history with us</h2>
                    <img src={Landing} />
                </div>
                <div className="login__header__form">
                    <img src={Shopee} />
                    <h2>Query Training Portal</h2>
                    <Form.Group controlId="loginEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control 
                            type="email" 
                            placeholder="jackie.chan@shopee.com" 
                            onChange={({target}) => {setEmail(target.value)}}
                        />
                    </Form.Group>
                    <Form.Group controlId="loginPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control 
                            type={showPassword ? "text" : "password"}
                            placeholder="Your password" 
                            onChange={({target}) => {setPassword(target.value)}}
                        />
                    </Form.Group>
                    <Form.Group controlId="loginCheckbox">
                        <Form.Check 
                            type="checkbox" 
                            label="Show Password" 
                            checked={showPassword}
                            onChange={() => {
                                setShowPassword(!showPassword)
                            }}
                        />
                    </Form.Group>
                    <Link to="/reset">Forgot Password?</Link>
                    <Button
                        onClick={handleSignIn}
                        className="signIn"
                        disabled={loading}
                    >
                        { loading 
                            ? <ThreeDots fill="white" /> 
                            : "Sign In" 
                        }
                    </Button>

                    <p>No account yet?</p>
                    <Link to="/signup">
                        <Button className="signUp">
                            Sign Up
                        </Button>
                    </Link>
                </div>
            </section>
            <section className="login__body">
                {/* <div className="login__body__video">
                    <EmbededVideo />
                </div> */}
                <Info />
            </section>
        </div>
    );
}

export default Login;