import { useState, useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import SButton from './reusables/SButton';
import { Form } from 'react-bootstrap';
import AppContext from './reusables/AppContext';
import { getDatabase, ref, set } from "firebase/database";
import firebase from '../assets/firebase';

import FeedbackImage from '../assets/images/feedback/feedback-icon.png';

const Feedback = ({
    show,
    handleClose
}) => {
    const { user } = useContext(AppContext);
    const [feedback, setFeedback] = useState('');
    const [loading, setLoading] = useState(false);

    const saveFeedback = () => {
        const unixTimeStamp = Math.round((new Date()).getTime() / 1000);
        const reset = () => {
            setFeedback('');
            setLoading(false);
        }

        setLoading(true)
        const db = getDatabase();
        set(ref(db, `feedback/${unixTimeStamp}`), {
            feedback,
            email: user.email
        }).then(() => {
            reset();
            alert('Thank you for your feedback!');
            handleClose();
        }).catch((err) => {
            reset();
            alert(err);
        })
    }

    return (
        <Modal
            className="feedback"
            show={show}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <img src={FeedbackImage} /> Send Feedback
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Help us improve your experience in using the Query Training Portal. Feel free to comment on parts we can improve on.
                </p>
                <Form.Control
                    as="textarea" 
                    placeholder="Enter feedback here"
                    onChange={({target}) => {
                        setFeedback(target.value)
                    }}
                    value={feedback}
                    disabled={loading}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    Close
                </Button>
                <SButton onClick={saveFeedback}>
                    Send Feedback
                </SButton>
            </Modal.Footer>
        </Modal>
    )
}

export default Feedback;