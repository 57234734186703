import { useContext } from 'react';
import Subheader from '../reusables/Subheader';
import LessonList from '../reusables/LessonList';
import lessons from '../../assets/lessons/sql';

import AppContext from "../reusables/AppContext";

const SQLLessons = () => {
    const { user } = useContext(AppContext);

    return (
        <div className="sqlLessons">
            <Subheader 
                header="SQL Optimization"
                body={
                    <div>
                        <p>4 lessons</p>
                        <p>Learn how to optimize your Presto SQL queries through this module.</p>
                    </div>
                }
            />
            <LessonList 
              lessons={lessons}
              sqlTraining
              lessonPlan="SQLTraining"
              quizesProgress={user?.['quiz-progress']}
              pageProgress={user?.['progress-pages']}
            />
        </div>
    )
}

export default SQLLessons;