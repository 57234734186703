const data = {
    "Lesson1": {
        title: "Lesson 1 SQL Basics",
        quiz: "Quiz 1  ",
        questions: [
            'L1Q1-Q1',
            'L1Q1-Q2',
            'L1Q1-Q3',
            'L1Q1-Q4',
            'L1Q1-Q5',
        ],
        lesson: [
            {
                outline: "Basic Syntax",
                content: (
                    <div>
                        <div>
                            <div id="list-topic1">
                                <h3>Lesson 1 SQL Basics </h3>

                                <p><b>Structured Query Language (SQL) </b>is a simple language used to query data from
                                    relational
                                    data
                                    <span id="sql-term"> WHERE </span> data is presented in tables with rows and columns.
                                </p>
                                <br />
                                <h4>Basic Syntax</h4>
                                <p>The basic form of an SQL query is </p><p class="code-format"><span id="sql-term">SELECT </span> A <span id="sql-term"> FROM </span> B <span id="sql-term"> WHERE </span> C</p>
                                <p></p>
                                <p><span id="sql-term">SELECT </span> desired columns A <span id="sql-term"> FROM </span> table B <span id="sql-term"> WHERE </span> condition C
                                    holds.</p>
                            </div>
                            <br />
                            <div id="list-topic2"><a id="list-topic2">
                                    <h4>Working with Columns</h4>
                                    <p>For the case of selecting single columns, we type in the name of the column after
                                        <span id="sql-term"> SELECT </span>.
                                        As
                                        an example, we are selecting the column orderid.</p>
                                    <p class="code-format"><span id="sql-term">SELECT  </span>
                                        orderid
                                        <span id="sql-term"> FROM </span> order_mart_order_profile </p>
                                    <p>In selecting multiple columns, we separate column names by a comma (‘,’). As an
                                        example,
                                        we
                                        are selecting the columns userid and shopid.</p>
                                    <p class="code-format"><span id="sql-term">SELECT </span>
                                        userid,
                                        shopid <span id="sql-term"> FROM </span> user_profile </p>
                                    <p>In order to <span id="sql-term">SELECT </span> all columns,
                                        we
                                        use an asterisk ('*') after the <span id="sql-term"><span id="sql-term">SELECT </span></span> statement.
                                        As
                                        an
                                        example, we are selecting all columns <span id="sql-term"> FROM </span> the table
                                        order_mart_order_profile.</p>
                                    <p class="code-format"><span id="sql-term">SELECT </span> *
                                        <span id="sql-term">  FROM </span> order_mart_order_profile </p>
                                    <p>New columns can be created by using operations with existing columns, or providing
                                        literal
                                        values..</p>
                                    <p class="code-format"><span id="sql-term">SELECT </span>
                                        total_price/100000, ‘literal column’, 1, (total_price -
                                        actual_shipping_fee)/100000 <span id="sql-term"> FROM </span>
                                        order_mart_order_profile </p>
                                </a>
                            </div>
                            <br />
                            <div id="list-topic3"><a id="list-topic4">
                                    <h4>Working with Tables</h4>
                                    <p>If there is no selected table to query, instant calculations can be done.</p>
                                    <p class="code-format"><span id="sql-term">SELECT </span> 24 * 7
                                    </p>
                            </a></div><a id="list-topic4">
                            <br />
                            <h4>Working with Rows</h4>
                            <p>Table rows can be filtered by adding conditions after the <span id="sql-term"> WHERE </span>
                                keyword.</p>
                            <p>Conditions are made by comparing columns with other columns or literal values (1, ‘SG’) using
                                operators $\gt, &gt;=, =, !=, &lt;=, \le$ . </p> <p class="code-format"><span id="sql-term"><span id="sql-term">SELECT </span></span> * <span id="sql-term"> FROM </span>
                                    user_profile
                                    <span id="sql-term"> WHERE </span> grass_region = ‘SG’ <br />
                                    <span id="sql-term">SELECT </span> *
                                    <span id="sql-term"> FROM </span> user_profile <span id="sql-term"> WHERE </span> is_seller
                                    != '0'</p>
                                    <br />
                            </a>
                        </div>
                    </div>
                )
            },
            {
                outline: "More Options",
                content: (
                    <div>
                        <h3>Lesson 1 SQL Basics</h3>

                        <div id="list-topic1">
                                <h4>More Options</h4>
                                <p>The ORDER BY Statement sorts the resulting rows. It can be sorted in descending
                                    order by
                                    adding the DESC keyword.
                                </p>
                                <p class="code-format"><span id="sql-term">SELECT </span> * <span id="sql-term"> FROM </span> order_mart__order_profile ORDER BY create_time
                                    <br />
                                    <span id="sql-term">SELECT </span> * <span id="sql-term"> FROM </span>
                                    order_mart__order_profile ORDER BY create_time DESC
                                </p>
                                <p>The LIMIT Statement limits the numbers of rows obtained from a query. </p>
                                <p class="code-format"><span id="sql-term">SELECT </span> * <span id="sql-term"> FROM </span> user_profile <span id="sql-term"> WHERE </span>
                                    is_seller = '1' <span id="sql-term">LIMIT</span> 20
                                </p>
                                <p>Here, the above query selects any 20 sellers from the account table, as there is
                                    no ORDER BY,
                                    we cannot guarantee any properties of the selected sellers.</p>
                                <p>💡 REMEMBER: Add limits to your queries when debugging them as it is much cheaper
                                    in terms of
                                    processing cost. Then, only remove them when your queries are finalised</p>
                            </div>
                        <div id="list-topic2">
                                <h4>Queries with Aggregation</h4>
                                <p>Aggregate functions can be used to find out attributes for groups/rows of data.
                                    They include
                                    the following functions:</p>
                                <p>MAX - max (column_name) - Finds the maximum value for a column</p>
                                <p>MIN - min (column_name) - Finds the minimum value for a column</p>
                                <p>AVG - avg (column_name) - Finds the average value for a column</p>
                                <p>SUM - sum (column_name) - Finds the sum of all values for a column</p>
                                <p class="code-format"><span id="sql-term">SELECT </span>
                                    max(total_price),
                                    min(total_price),
                                    avg(total_price),
                                    sum(total_price)
                                    <span id="sql-term"> FROM </span>
                                    order_mart__order_profile
                                </p>
                                <p>COUNT(*) - Finds the number of rows in a selection</p>
                                <p class="code-format"><span id="sql-term">SELECT </span> COUNT(*) <span id="sql-term"> FROM </span> order_mart__order_profile
                                </p>
                                <p>The above query returns the number of orders in the order_mart__order_profile.
                                </p>
                                <p>COUNT(column) - Finds the number of non-null values for a column</p>
                                <p class="code-format"> <span id="sql-term">SELECT </span> COUNT(voucher_code) <span id="sql-term"> FROM </span> order_mart__order_profile
                                </p>
                                <p>The above query returns the orders accompanied with a voucher code.</p>
                                <p>COUNT(DISTINCT) - Finds the number of distinct values for a column</p>
                                <p class="code-format"><span id="sql-term">SELECT </span> COUNT(DISTINCT grass_region)
                                    <span id="sql-term"> FROM </span> user_profile</p>
                                <p>The above query returns the number of countries in the user_profile.</p>
                                <br />  
                        </div>
                    </div>
                )
            }, 
            {
                outline: "Using Group By",
                nextQuiz: '/SQLTraining/quiz/Lesson1/L1Q1-Q1',
                content: (
                    <div>
                        <h3>Lesson 1 SQL Basics</h3>
                        <div id="list-topic1">
                                <h4>Using GROUP BY </h4>
                                <p>You can run aggregation functions on sub-groups instead of the entire results set
                                    using the
                                    GROUP BY keyword. It works by grouping all rows having the same values for the
                                    selected
                                    columns together in the same subgroup. The aggregation functions would then be
                                    applied to
                                    each subgroup instead of all the rows. Refer to the following examples:</p>
                                <p>Example #1</p>
                                <p class="code-format"><span id="sql-term">SELECT </span>
                                    grass_region,
                                    count(*)
                                    <span id="sql-term"> FROM </span>
                                    user_profile
                                    GROUP BY
                                    grass_region
                                </p>
                                <p>The above query counts the number of accounts for each country Shopee operates in.
                                </p>
                                <p>Example #2</p>
                                <p class="code-format"><span id="sql-term">SELECT </span>
                                    currency,
                                    sum(amount)
                                    <span id="sql-term"> FROM </span>
                                    order_mart__order_item_profile
                                    GROUP BY
                                    currency
                                </p>
                                <p>The above query counts the number of items sold for each currency used to purchase
                                    them.
                                </p>
                                <br />
                            </div>
                    </div>
                )
            }
        ]
    },
    "Lesson2": {
        title: "Lesson 2 Advanced SQL",
        quiz: "Quiz 2  ",
        questions: [
            'L1Q2-Q1',
            'L1Q2-Q2',
            'L1Q2-Q3',
            'L1Q2-Q4',
            'L1Q2-Q5',
        ],
        lesson: [
            {
                outline: "Using the NOT operator",
                content: (
                    <div>
                            <h3>Lesson 2 Advanced SQL</h3>
                            <div id="list-topic1">
                            <h4>Complex Queries</h4>
                            <p>Looking back to the <span id="sql-term"> WHERE </span> filters, it turns out that it is possible to modify our conditions
                                using logical operations. Similarly, we can include more than one condition in our query.
                            </p>

                            <h4>Using the NOT Operator </h4>
                            <p>The first logical operation on conditions is the NOT operator.
                            </p>
                            <p>If the NOT operator is appended before a value that is True, its result will be False. <br />
                                Else, if the operator NOT is appended before a value that is False, its result will be True.
                            </p>
                            <p>For example, we are selecting all columns from the table called order_mart__order_profile and
                                we set our results to only contain those where is_cross_border is NOT equal to 1.</p>
                            <p class="code-format"><span id="sql-term">SELECT </span> * <span id="sql-term"> FROM </span> order_mart__order_profile <span id="sql-term"> WHERE </span> NOT is_cross_border = 1
                            </p>
                            <p>This query selects all non-cross border orders. This is equivalent to the following query:
                            </p>
                            <p class="code-format"><span id="sql-term">SELECT </span> * <span id="sql-term"> FROM </span> order_mart__order_profile <span id="sql-term"> WHERE </span> is_cross_border != 1
                            </p>
                            <br />
                            </div> 
                    </div>
                )
            },
            {
                outline: "Using the AND and OR Operators",
                content: (
                    <div>
                            <h3>Lesson 2 Advanced SQL</h3>
                            <div id="list-topic1">
                                <h4>Using the <span id="sql-term">AND</span> and <span id="sql-term">OR</span> Operators </h4>
                                <p>For multiple conditions, we use the <span id="sql-term">AND</span> and <span id="sql-term">OR</span> operators.</p>
                                <p>In using the <span id="sql-term">AND</span> statement:</p>
                                <p>If the first and second value are considered True, the result would render True when using
                                    the <span id="sql-term">AND</span> statement. If one of the values is False, the result is False.</p>
                                <p>In using the <span id="sql-term">OR</span> statement: </p>
                                <p>If any of the first and second values are True, the result would render True when using the
                                    <span id="sql-term"> OR </span> statement. If none of the values are True, the result will be False.</p>
                                <p class="code-format"><span id="sql-term">SELECT </span> * <span id="sql-term"> FROM </span> order_mart__order_profile <span id="sql-term"> WHERE </span> grass_date &gt;=
                                    date('2018-07-01') <span id="sql-term">AND</span> grass_date &lt;= date('2018-07-31') <br />
                                        <span id="sql-term">SELECT </span> * <span id="sql-term"> FROM </span> user_profile <span id="sql-term"> WHERE </span> grass_region != 'PH' <span id="sql-term"><span id="sql-term">AND</span></span> grass_region !='VN' <br />
                                        <span id="sql-term">SELECT </span> * <span id="sql-term"> FROM </span> user_profile <span id="sql-term"> WHERE </span> grass_region = 'PH' <span id="sql-term">OR</span> grass_region ='VN'
                                </p>
                            </div>
                            <br />
                    </div>
                )
            },
            {
                outline: "Using the IN Operator",
                content: (
                <div>
                        <h3>Lesson 2 Advanced SQL</h3>

                        <div id="list-topic1">
                        <h4>Using the IN Operator </h4>
                        <p>The <span id="sql-term">IN </span> operator can be used to check if a column is part of a list of literal values or even
                            a table. This allows us to replace commonly used but tedious and complicated checks.</p>
                        <br />
                        <h5>For Using IN with Literal Lists</h5>
                        <p>For example, a common way to check for valid orders would be to check if the status_ext of
                            the order is equal to 1 or 2 or 4 or 11 or 12 or 13 or 14 or 15. We can simply use an IN
                            operator to check if a column value is within a list.</p>
                        <p class="code-format"><span id="sql-term">SELECT </span> * <span id="sql-term"> FROM </span> order_mart__order_profile <span id="sql-term"> WHERE </span> status_ext <span id="sql-term">IN </span> (1, 2, 4, 11,
                            12, 13, 14, 15)</p>
                        <p>This is equivalent to: </p>
                        <p class="code-format"><span id="sql-term">SELECT </span> * <span id="sql-term"> FROM </span> order_mart__order_profile <span id="sql-term"> WHERE </span>
                            status_ext = 1 <span id="sql-term"> OR </span> 
                            status_ext = 2 <span id="sql-term"> OR </span> 
                            status_ext = 4 <span id="sql-term"> OR </span> 
                            status_ext = 11 <span id="sql-term"> OR </span> 
                            status_ext = 12 <span id="sql-term"> OR </span> 
                            status_ext = 13 <span id="sql-term"> OR </span> 
                            status_ext = 14 <span id="sql-term"> OR </span> 
                            status_ext = 15
                        </p>
                        <br />
                        <h5>For Using IN with a Table</h5>
                        <p>We can compare column values with the columns obtained from a sub-table. </p>
                        <p class="code-format"><span id="sql-term">SELECT </span>
                            count(*)
                            <span id="sql-term"> FROM </span>
                            user_profile
                            <span id="sql-term"> WHERE </span>
                            shopid <span id="sql-term">IN </span>
                            (<span id="sql-term">SELECT </span>
                            shopid
                            <span id="sql-term"> FROM </span>
                            order_mart__order_profile)
                        </p>
                        <p>For example, with the above query. We first find out the list of shopids with an order. The
                            <span id="sql-term"> IN </span> keyword then tries to find all rows where the shopid exists within the results of the
                            subquery. This same operation can be executed with JOIN as well, which will be taught in the
                            next lesson.</p>
                        <p>💡 REMEMBER: The logical operator <span id="sql-term">NOT</span> can be used with the <span id="sql-term">IN </span> keyword for the opposite
                            effect.</p>
                        <p class="code-format"><span id="sql-term">SELECT </span>
                            count(*)
                            <span id="sql-term"> FROM </span>
                            user_profile
                            <span id="sql-term"> WHERE </span>
                            shopid <span id="sql-term">NOT</span> <span id="sql-term">IN </span>
                            (<span id="sql-term">SELECT </span>
                            shopid
                            <span id="sql-term"> FROM </span>
                            order_mart__order_profile)
                        </p>
                        <p>This would retrieve all shopids without any order.</p>
                        <p>Please note that it is <span id="sql-term">NOT</span> recommended to use <span id="sql-term">IN </span> + subquery for large data such as one
                            described in this example as the operation will be very slow. <span id="sql-term"> LEFT JOIN </span> is one of possible
                            solutions. It will be covered in Lesson 3 about Relational Queries.</p>
                        </div>
                        <br />
                </div>
                )
            },
            {
                outline: "Using the HAVING Keyword",
                content: (
                    <div>
                        <h3>Lesson 2 Advanced SQL</h3>
                        <div id="list-topic1">

                        <h4>Using the HAVING Operator</h4>
                        <p>What happens if we want to compare our aggregated results? You cannot use the <span id="sql-term"> WHERE </span> keyword
                            as our condition cannot be compared to using individual rows as <span id="sql-term"> WHERE </span> filters the row before
                            the aggregation.</p>
                        <p>What we have to use instead would be the <span id="sql-term"> HAVING </span> keyword which allows you to filter your rows
                            after the aggregation.</p>
                        <p class="code-format"><span id="sql-term">SELECT </span>
                            shopid
                            <span id="sql-term"> FROM </span>
                            order_mart__order_profile
                            <span id="sql-term"> GROUP BY </span>
                            shopid
                            <span id="sql-term"> HAVING </span>
                            COUNT(*) &gt; 1000
                        </p>
                        <p>The above query finds a list of shopids for all shops whose total number of orders exceeds
                            1000.</p>
                        </div>
                        <br /> 
                </div>
                )
            },
            {
                outline: "Column specific Operations",
                content: (
                    <div>
                        <h3>Lesson 2 Advanced SQL</h3>
                        <div id="list-topic1">
                                <h4>Column Specific Operations</h4>
                                <p><a id="list-topic1">Thus far, we have learnt all the various operations to manipulate and filter the rows
                                    that we
                                    would like to obtain. There are many other functions we can use to modify and
                                    manipulate
                                    individual columns for us to use as well, as there are a lot of available columnar
                                    functions, this summary would only include the most commonly used functions in the
                                    BI
                                    context. For information on all the functions available on Cargo, please refer to
                                    the
                                    following </a><a href="https://cwiki.apache.org/confluence/display/Hive/LanguageManual+UDF" target="_blank"><b>link</b></a>.
                                </p>
                            
                        </div>
                        <br />
                    </div>
                )
            },
            {
                outline: "Basic Arithmetics and Aliasing",
                content: (
                    <div>
                        <h3>Lesson 2 Advanced SQL</h3>

                        <div id="list-topic1"><a id="list-topic1">

                                <h4>Basic Arithmetics and Aliasing</h4>
                                <p>You can create new columns by doing any basic column operations on the original
                                    columns of
                                    the table.</p>
                                <p>Doing so would return you a new column named _c*, where * is a number starting from 0
                                    and
                                    increases for every new column that you create. As it is difficult to keep track of
                                    which
                                    columns if you have too many new columns, you can rename any columns in your query
                                    using the
                                    <span id="sql-term"> AS </span> keyword.</p>
                                <p>The most simple of these column functions would be the basic arithmetic functions.
                                    This
                                    includes:</p>
                                <ul>
                                    <li>Addition, (+)</li>
                                    <li>Subtraction, (-)</li>
                                    <li>Multiplication, (*)</li>
                                    <li>Division, (/)</li>
                                    <li>Modulus, (\%)</li>
                                </ul>
                                <p class="code-format"><span id="sql-term">SELECT </span>
                                    orderid,
                                    shopid,
                                    total_price - actual_shipping_fee
                                    <span id="sql-term"> FROM </span>
                                    order_mart__order_profile
                                </p>
                            </a>
                        </div>
                        <br /> 
                    </div>
                )
            },
            {
                outline: "String Manipulation",
                content: (
                    <div class="tab-content" id="nav-tabContent">
                        <h3>Lesson 2 Advanced SQL</h3>

                        <div id="list-topic1"><a id="list-topic1">

                                <h4>String Manipulation</h4>
                                <h5> FROM_BASE64</h5>
                                <p>Certain columns in the BI tables are encoded in Base64 for easier storage. To make it
                                    readable, one can apply the from_base64 function followed by the decode function to
                                    make
                                    them
                                    readable again. These columns are often columns related to names, such as the shop
                                    name
                                    or
                                    the user name, and can be identified with '=' characters at the end of the string,
                                    e.g.
                                    aGVsbG8gd29ybGQ=, c3FsIGxlc3Nvbg==</p>

                                <p class="code-format"><span id="sql-term">SELECT </span> from_base64(name) <span id="sql-term"> AS </span> shop_name, shopid <span id="sql-term"> FROM </span>
                                    shopee_account_db__shop_tab;
                                </p>
                                <h5>SUBSTR</h5>
                                <p>This function slices the original string into the desired length. A very common use
                                    case
                                    would be to filter by
                                    countries within the Order Tab as the Order Tab has a currency column instead of
                                    country
                                    columns. (This
                                    allows us to convert 'SGD' to 'SG', 'IDR' to 'ID' and so on.)
                                </p>

                                <p class="code-format"><span id="sql-term">SELECT </span> orderid, SUBSTR(currency, 1, 2) <span id="sql-term"> AS </span> country from
                                    order_mart__order_profile
                                </p>

                                <h5>GET_JSON_STRING</h5>
                                <p>JSON Strings are often used when you have to store multiple information in one single
                                    column.
                                    An example of a JSON string is as follows. </p>

                                <p class="code-format">{`{"city":"Singapore","state":"Singapore","address":{"road":"1
                                    Fusionopolis
                                    Place"}}`}
                                </p>
                                <p>There is a function GET_JSON_STRING that allows you to filter out the desired
                                    information
                                    from the JSON column. For more information, please refer to this link.</p>
                            </a>
                        </div>
                        <br />
                    </div>
                )
            },
            {
                outline: "Using the IF Keyword",
                content: (
                    <div>
                        <h3>Lesson 2 Advanced SQL</h3>

                        <div id="list-topic1"><a id="list-topic1">

                                <h4>Conditional Functions</h4>
                                <h5>Using the IF keyword</h5>
                                <p>You can apply an <span id="sql-term">IF</span> keyword to your column such that you will have different values
                                    when
                                    certain conditions are followed.</p>
                                <p class="code-format"><span id="sql-term">SELECT </span>
                                    userid,
                                    <span id="sql-term">IF</span>(status <span id="sql-term">IN </span> (2,3), 1, 0)
                                    <span id="sql-term"> AS </span> status_fraud
                                    <span id="sql-term"> FROM </span>
                                    user_profile
                                </p>
                            </a>
                        </div>
                        <br />
                        <div id="list-topic2"><a id="list-topic2">
                                <h5>Using the CASE keyword</h5>
                                <p><span id="sql-term">CASE</span> functions like multiple IFs, allowing you to check and set a value based on
                                    multiple
                                    conditions. This can be useful when you need to do things such as bucketing.</p>
                                <p class="code-format"><span id="sql-term">SELECT </span>
                                    <span id="sql-term">CASE</span>
                                    <span id="sql-term"> WHEN </span> (total_price) &lt; 50000) <span id="sql-term">THEN</span> 50 <span id="sql-term"> WHEN </span> (total_price)&gt;= 50000) <span id="sql-term">AND</span> (total_price) &lt;
                                            100000) <span id="sql-term">THEN</span> 100 <span id="sql-term"> WHEN </span> (total_price)&gt;= 100000) <span id="sql-term">AND</span> (total_price) &lt; 150000)
                                                <span id="sql-term"> THEN </span> 150 <span id="sql-term"> WHEN </span> (total_price)&gt;= 150000) <span id="sql-term">AND</span> (total_price) &lt; 200000) <span id="sql-term"> THEN</span>
                                                    200 <span id="sql-term">ELSE</span> 250 <span id="sql-term">END</span>) <span id="sql-term"> AS </span> bucket, orderid <span id="sql-term"> FROM </span> order_mart__order_profile
                                                    <span id="sql-term"> GROUP BY </span> substr(cast(grass_date as varchar), 1, 7) </p> </a> </div>
                                                    <br />
                                                    <div id="list-topic3"><a id="list-topic3">
                                                            <h5>Using the COALESCE
                                                                keyword</h5>
                                                            <p>COALESCE takes in two values, and returns the second
                                                                value if
                                                                the
                                                                first
                                                                value is null, otherwise it will return the first
                                                                column.
                                                                This
                                                                function
                                                                is especially useful when facing a column with a lot of
                                                                nulls.</p>
                                                            <p class="code-format"><span id="sql-term">SELECT </span> COALESCE(NULL, 1, 2, 'SG');
                                                            </p>
                                                        </a></div>

                                                    <br />
                        </div>
                )
            },
            {
                outline: "Date-time Function",
                nextQuiz: "/SQLTraining/quiz/Lesson2/L1Q2-Q1",
                content: (
                    <div>
                        <h3>Lesson 2 Advanced SQL</h3>

                        <div id="list-topic1"><a id="list-topic1">
                                <h4>Date-time Functions</h4>
                                <h5> FROM_UNIXTIME</h5>
                                <p>A very commonly used SQL function. Date / time values are often
                                    stored in
                                    unix time in our tables, which indicate the number of seconds that
                                    have
                                    passed 1970-01-01 00:00. This function allows us to convert our time
                                    column from this not useful to one that is more human readable and
                                    useful for us. It takes in the unixtime column as the first argument
                                    and
                                    the time format as the second argument.
                                </p>
                                <p class="code-format"><span id="sql-term">SELECT </span>
                                    <span id="sql-term"> FROM_UNIXTIME</span>(event_timestamp),
                                    count(distinct orderid) <span id="sql-term"> AS </span> orders
                                    <span id="sql-term"> FROM </span>
                                    order_mart__order_profile
                                    <span id="sql-term"> GROUP</span>
                                    <span id="sql-term"> FROM_UNIXTIME</span>(event_timestamp)
                                </p>
                                <br />
                            </a>
                        </div>
                    </div>
                )
            }
        ]
    },
    "Lesson3": {
        title: "Lesson 3 Relational Query",
        quiz: "Quiz 3  ",
        questions: [
            'L1Q3-Q1',
            'L1Q3-Q2',
            'L1Q3-Q3',
            'L1Q3-Q4',
            'L1Q3-Q5',
        ],
        lesson: [
            {
                outline: "Table Aliasing",
                content: (
                    <div>
                        <div id="list-topic1"><a id="list-topic1">
                                <h3>Lesson 3 Relational Query</h3>
                                <h4>Table Aliasing</h4>
                                <p>You can rename the tables you use in your SQL query, this is done so by simply
                                    adding the
                                    alias behind the table name in your query. Aliasing is extremely useful in more
                                    complicated
                                    queries as it makes it much easier to write your complex query. All subsequent
                                    lesson
                                    content will make heavy use of aliasing. <br /><br /> An example is given here:
                                </p>
                                <p class="code-format"><span id="sql-term">SELECT </span>
                                    *
                                    <span id="sql-term"> FROM </span>
                                    user_profile up
                                </p>
                                <br />
                            </a>
                        </div>
                    </div>
                )
            },
            {
                outline: "Relational Queries",
                content: (
                    <div>
                    <div class="tab-pane fade show active" id="list-topic1" role="tabpanel" aria-labelledby="list-topic1-list">
                        <div id="list-topic1"><a id="list-topic1">
                                <h3>Lesson 3 Relational Query</h3>
                                <h4>Relational Queries</h4>
                                <p>The most common use case for the SQL language is to obtain information from two or
                                    more
                                    related tables. These use cases include (not exhaustive):
                                </p>
                                <ul>
                                    <li>Adding additional columns into your results that don't originally exist in the
                                        primary
                                        table.</li>
                                    <li>Filtering your primary table by a column that does not exist in your primary
                                        table</li>
                                </ul>
                                <p>This type of queries are called relational queries as we often make use of the
                                    predefined
                                    relationships between the tables.</p>

                            </a>
                        </div>
                        <br />
                    </div>
                </div>
                )
            },
            {
                outline: "Using the JOIN Keyword",
                content: (
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="list-topic1" role="tabpanel" aria-labelledby="list-topic1-list">
                            <div id="list-topic1"><a id="list-topic1">
                                    <h3>Lesson 3 Relational Query</h3>
                                    <h5>Using the JOIN Keyword</h5>
                                    <p><span id="sql-term"> JOIN </span> is used to define the relationship between two
                                        tables by specifying the common
                                        column(s)
                                        in the chosen tables. The primary syntax of a join query is given here:</p>
                                    <p class="code-format"><span id="sql-term">SELECT </span>
                                        op.orderid
                                        <span id="sql-term"> FROM </span>
                                        order_mart__order_profile op
                                        <span id="sql-term"> JOIN </span>
                                        user_profile up
                                        <span id="sql-term"> ON </span>
                                        up.shopid = op.shopid
                                        <span id="sql-term"> WHERE </span>
                                        up.registration_time = date('2017-03-01')
                                    </p>
                                    <p>What this query does is that it retrieves a list of all orders from shops that
                                        were
                                        created
                                        last month. While the list of all orders is obtained from the
                                        order_mart__order_profile, the
                                        creation date of shops were found in user_profile. This resulted in us having to
                                        join the
                                        user_profile to the order_mart__order_profile to retrieve the information that
                                        is
                                        required.
                                        As the user_profile is identified primarily by the shopid column, which refers
                                        to
                                        the same
                                        shopid column in the order_tab, we can then <span id="sql-term"> JOIN </span>
                                        these two tables by using the <span id="sql-term"> ON </span>
                                        keyword,
                                        which specifies columns that ought to have the same values, on the two columns,
                                        up.shopid
                                        and op.shopid.
                                    </p>
                                    <p>Whenever a <span id="sql-term"> JOIN </span> is used within a query, prefixes
                                        (table_name.column_name) will have
                                        to be
                                        added to any reference to a column whose column name might exist in both tables
                                        (such as
                                        userid). While prefixes are not required when referencing a column with a unique
                                        name. It is
                                        recommended that you add prefixes to all your columns for your SQL queries to be
                                        more
                                        readable by yourself and other people. As such, we will be prefixing all column
                                        references
                                        in the subsequent examples as well.</p>
                                    <h6>The JOIN Types</h6>
                                    <p>Whenever you join two tables using the normal syntax, you have to define a
                                        relationship
                                        between the two tables, equating one column of the first table to another column
                                        of
                                        the
                                        second table. When doing a join, it is highly likely that there exists rows in
                                        either table
                                        that do not have a matching row according to the join condition. For example,
                                        with
                                        reference
                                        to the following schema:
                                    </p>
                                    <table class="table table-striped">
                                        <caption>Table 1. student_tab</caption>
                                        <thead>
                                            <tr>
                                                <th scope="col">id</th>
                                                <th scope="col">name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>Alice</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <td>Bob</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">3</th>
                                                <td>Charlie</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table class="table table-striped">
                                        <caption>Table 2. subject_tab</caption>
                                        <thead>
                                            <tr>
                                                <th scope="col">id</th>
                                                <th scope="col">name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>Art</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <td>Biology</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">3</th>
                                                <td>Chemistry</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table class="table table-striped">
                                        <caption>Table 3. enroll_tab</caption>
                                        <thead>
                                            <tr>
                                                <th scope="col">studentid</th>
                                                <th scope="col">subjectid</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>1</td>
                                            </tr>
                                            <tr>
                                                <td>1</td>
                                                <td>2</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>2</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <p>If we were to apply the following query:</p>
                                    <p class="code-format"><span id="sql-term">SELECT </span>
                                        st.name,
                                        subt.name
                                        <span id="sql-term"> FROM </span>
                                        student_tab st
                                        <span id="sql-term"> JOIN </span>
                                        enroll_tab et
                                        <span id="sql-term"> ON </span>
                                        st.id = et.studentid
                                        <span id="sql-term"> JOIN </span>
                                        subject_tab subt
                                        <span id="sql-term"> ON </span>
                                        subt.id = et.subjectid
                                    </p>
                                    <p>The row (2, Bob) from student_tab will not have a matching key from the
                                        enroll_tab as
                                        its id,
                                        2, does not
                                        appear in the enroll_tab's studentid column at all.
                                        On the other hand, the row (3, Chemistry) will not have a matching from the
                                        enroll) tab as
                                        well as its id, 3,
                                        does not appear in enroll_tab's subjectid column at all.
                                        There are four types methods to join tables together, and these methods solely
                                        differ based
                                        on how these
                                        rows are dealt with in the results.
                                    </p>

                                </a>
                            </div>
                            <br />
                        </div>
                    </div>
                )
            },
            {
                outline: "The INNER Join",
                content: (
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="list-topic1" role="tabpanel" aria-labelledby="list-topic1-list">
                            <div id="list-topic1"><a id="list-topic1">
                                    <h3>Lesson 3 Relational Query</h3>
                                    <h6>INNER JOIN </h6>
                                    <p>In an <span id="sql-term">INNER JOIN </span>, these rows with no matching keys are
                                        removed from the results.
                                        The
                                        inner
                                        join is also the default joining method. As such, the resultant table from the
                                        above
                                        query
                                        will be the following:</p>

                                    <table class="table table-striped">
                                        <caption>Table 4. Sample Table using INNER JOIN </caption>
                                        <thead>
                                            <tr>
                                                <th scope="col">studentname</th>
                                                <th scope="col">subjectname</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Alice</td>
                                                <td>Art</td>
                                            </tr>
                                            <tr>
                                                <td>Alice</td>
                                                <td>Biology</td>
                                            </tr>
                                            <tr>
                                                <td>Charlie</td>
                                                <td>Biology</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p>Here, the rows (2, Bob) and (3, Chemistry) are removed from the results entirely.
                                    </p>
                                </a>
                            </div>
                            <br />
                        </div>
                    </div>
                )
            },
            {
                outline: "The LEFT Join",
                content: (
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="list-topic1" role="tabpanel" aria-labelledby="list-topic1-list">
                            <div id="list-topic1"><a id="list-topic1">
                                    <h3>Lesson 3 Relational Query</h3>
                                    <h6> LEFT JOIN </h6>
                                    <p>In a left join, these rows with no matching keys from the first or left table
                                        will be
                                        kept in
                                        the results, with the second column having a value of null. The syntax of a left
                                        join is as
                                        follows (Use a "<span id="sql-term"> LEFT JOIN </span>" keyword instead of
                                        "JOIN"):</p>

                                    <p class="code-format"><span id="sql-term">SELECT </span>
                                        st.name,
                                        et.subjectid
                                        <span id="sql-term"> FROM </span>
                                        student_tab st
                                        <span id="sql-term"> LEFT JOIN </span>
                                        enroll_tab et
                                        <span id="sql-term"> ON </span>
                                        st.id = et.studentid
                                    </p>
                                    <p>As such, the resultant table from the above query will be the following:</p>
                                    <table class="table table-striped">
                                        <caption>Table 5. Sample Table using LEFT JOIN </caption>
                                        <thead>
                                            <tr>
                                                <th scope="col">studentname</th>
                                                <th scope="col">subjectid</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Alice</td>
                                                <td>1</td>
                                            </tr>
                                            <tr>
                                                <td>Alice</td>
                                                <td>2</td>
                                            </tr>
                                            <tr>
                                                <td>Charlie</td>
                                                <td>2</td>
                                            </tr>
                                            <tr>
                                                <td>Bob</td>
                                                <td>null</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <p>Left join also serves as a substitute for the <span id="sql-term">IN </span>
                                        operation that we covered in Lesson
                                        2. One
                                        of the examples given was to retrieve the number of shopids without any order.
                                    </p>
                                    <p class="code-format"><span id="sql-term">SELECT </span>
                                        count(*)
                                        <span id="sql-term"> FROM </span>
                                        user_profile
                                        WHERE
                                        shopid <span id="sql-term">NOT</span> <span id="sql-term">IN </span>
                                        (<span id="sql-term">SELECT </span>
                                        shopid
                                        <span id="sql-term"> FROM </span>
                                        order_mart__order_profile)
                                    </p>
                                    <p>It could be translated into left join operation. The condition inside the where
                                        clause
                                        indicates that the query is looking for the shopids on the left table
                                        (user_profile)
                                        that
                                        cannot be matched with the right table, providing the same logic as the query
                                        utilizing not
                                        in.</p>
                                    <p class="code-format"><span id="sql-term">SELECT </span>
                                        count(*)
                                        <span id="sql-term"> FROM </span>
                                        user_profile up
                                        <span id="sql-term"> LEFT JOIN </span> order_mart__order_profile op
                                        <span id="sql-term"> ON </span> up.shopid = op.shopid
                                        WHERE
                                        op.shopid is null
                                    </p>
                                    <p>Similarly, inner join can be used to replace the <span id="sql-term">IN </span>
                                        operation. In this case, the
                                        requirement
                                        is usually more straight-forward. For example, to retrieve the number of shopids
                                        with at
                                        least an order, it will be a similar query as above with inner join and without
                                        including
                                        the null (mismatched) shopids.</p>
                                    <p class="code-format"><span id="sql-term">SELECT </span>
                                        count(*)
                                        <span id="sql-term"> FROM </span>
                                        user_profile up
                                        <span id="sql-term"> INNER JOIN </span> order_mart__order_profile op
                                        <span id="sql-term"> ON </span> up.shopid = op.shopid
                                    </p>
                                </a>
                            </div>
                            <br />
                        </div>
                    </div>
                )
            },
            {
                outline: "The RIGHT Join",
                content: (
                <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="list-topic1" role="tabpanel" aria-labelledby="list-topic1-list">
                            <div id="list-topic1"><a id="list-topic1">
                                    <h3>Lesson 3 Relational Query</h3>
                                    <h6> RIGHT JOIN </h6>
                                    <p>In a right join, these rows with no matching keys from the second or right table
                                        will be kept
                                        in the results, with the first column having a value of null. The syntax of a
                                        right join is
                                        as follows (Use a "<span id="sql-term"> RIGHT JOIN </span>" keyword instead of "<span id="sql-term"> JOIN </span>"):</p>

                                    <p class="code-format"><span id="sql-term">SELECT </span>
                                        et.studentid,
                                        subt.name
                                        <span id="sql-term"> FROM </span>
                                        enroll_tab et
                                        <span id="sql-term"> RIGHT JOIN </span>
                                        subject_tab subt
                                        <span id="sql-term"> ON </span>
                                        subt.id = et.subjectid
                                    </p>
                                    <p>The resultant table from the above query will be the following.</p>
                                    <table class="table table-striped">
                                        <caption>Table 6. Sample Table using RIGHT JOIN </caption>
                                        <thead>
                                            <tr>
                                                <th scope="col">studentid</th>
                                                <th scope="col">subjectname</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Art</td>
                                            </tr>
                                            <tr>
                                                <td>1</td>
                                                <td>Biology</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Biology</td>
                                            </tr>
                                            <tr>
                                                <td>null</td>
                                                <td>Chemistry</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </a>
                            </div>
                            <br />
                        </div>
                    </div>
                )
            },
            {
                outline: "The FULL OUTER JOIN",
                nextQuiz: "/SQLTraining/quiz/Lesson3/L1Q3-Q1",
                content: (
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="list-topic1" role="tabpanel" aria-labelledby="list-topic1-list">
                            <div id="list-topic1"><a id="list-topic1">
                                    <h3>Lesson 3 Relational Query</h3>
                                    <h6>FULL OUTER JOIN </h6>
                                    <p>In a full outer join, the rows with no matching keys from both tables will be
                                        kept in the
                                        results, with the corresponding missing column having a value of null. The
                                        syntax of a full
                                        outer join is similar to that of the other joins. Use a "FULL OUTER JOIN"
                                        keyword instead of
                                        "JOIN".</p>

                                    <h6> CROSS JOIN </h6>
                                    <p>(Wait, I thought you said four joins.) There's actually one last join, the cross
                                        join, which
                                        should ideally not be used in any of the queries at all. A cross join matches
                                        every single
                                        row from your first table with every single row of the second table, resulting
                                        in a enormous
                                        table that has m * n rows, if your tables have m and n rows respectively. This
                                        join is
                                        performed by leaving out your ON condition. While this join does has its own use
                                        case, it's
                                        rare for this join to be needed. I'm actually only highlighting this because it
                                        is really
                                        important you don't forget your ON condition, as these type of queries often
                                        will crash
                                        Cargo.</p>

                                    <p class="code-format"><span id="sql-term">SELECT </span>
                                        st.name,
                                        subt.name
                                        <span id="sql-term"> FROM </span>
                                        student_tab st
                                        <span id="sql-term"> JOIN </span>
                                        subject_tab subt
                                    </p>
                                    <p>The results for this query will be as follows:</p>

                                    <table class="table table-striped">
                                        <caption>Table 7. Sample Table using CROSS JOIN </caption>
                                        <thead>
                                            <tr>
                                                <th scope="col">studentname</th>
                                                <th scope="col">subjectname</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Alice</td>
                                                <td>Art</td>
                                            </tr>
                                            <tr>
                                                <td>Alice</td>
                                                <td>Biology</td>
                                            </tr>
                                            <tr>
                                                <td>Alice</td>
                                                <td>Chemistry</td>
                                            </tr>
                                            <tr>
                                                <td>Bob</td>
                                                <td>Art</td>
                                            </tr>
                                            <tr>
                                                <td>Bob</td>
                                                <td>Biology</td>
                                            </tr>
                                            <tr>
                                                <td>Bob</td>
                                                <td>Chemistry</td>
                                            </tr>
                                            <tr>
                                                <td>Charlie</td>
                                                <td>Art</td>
                                            </tr>
                                            <tr>
                                                <td>Charlie</td>
                                                <td>Biology</td>
                                            </tr>
                                            <tr>
                                                <td>Charlie</td>
                                                <td>Chemistry</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </a>
                            </div>
                        </div>
                    </div>
                )
            }
        ]
    },
    "Lesson4": {
        title: "Lesson 4 Complex Queries and Tips & Tricks",
        quiz: "Quiz 4  ",
        questions: [
            'L1Q4-Q1',
        ],
        lesson: [
            {
                outline: "Introduction",
                content: (
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="list-topic1" role="tabpanel" aria-labelledby="list-topic1-list">
                            <div id="list-topic1"><a id="list-topic1">
                                    <h3>Lesson 4 Complex Queries and Other Tips &amp; Tricks</h3>
                                    <p>After going through the last three lessons, you should be able to write all kinds
                                        of
                                        queries
                                        and retrieve all kinds of information from Lumos. Of course, there is more to
                                        SQL
                                        other than
                                        querying data. SQL is also used to create tables and populate data. In this
                                        lesson,
                                        we will
                                        no longer be covering any new topics in SQL, but we will be answering some FAQs
                                        and
                                        tackling
                                        some complex queries.</p>
                                </a></div>
                            <br />
                        </div>
                    </div>
                )
            },
            {
                outline: "On Presto SQL",
                content: (
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="list-topic1" role="tabpanel" aria-labelledby="list-topic1-list">
                            <div id="list-topic1"><a id="list-topic1">
                                    <h3>Lesson 4 Complex Queries and Other Tips &amp; Tricks</h3>
                                    <h4>FAQs</h4>
                                    <h6>Is the Presto syntax not the same as any of the common SQL dialect we find
                                        online? For
                                        example, mySQL, PostgresQL, sqlite (Used for the non-Presto questions). Which
                                        database do we
                                        use in Presto and what dialect are we using exactly?</h6>
                                    <p>Long answer: The data you access with Lumos is not stored in any of the
                                        traditional SQL
                                        databases. As the amount of data Shopee has sums to more than six thousand
                                        terabytes.
                                        Traditional data storage softwares such as SQL databases are no longer viable
                                        for us to
                                        store our data for reporting and querying purposes. As such, our data is stored
                                        using a
                                        software called Hadoop, which allows us to store data in a distributed manner
                                        conveniently.
                                        As such, Lumos uses a special dialect called Presto, a SQL-like querying
                                        language designed
                                        specifically to query data from Hadoop. The SQL query submitted in SQL Lab (SQL
                                        editor in
                                        Lumos) is compiled and processed into multiple stages across worker nodes to
                                        achieve more
                                        parallelism and faster processing.</p>
                                    <p>Short answer: Our data is no longer stored in SQL databases as they cannot handle
                                        Big Data.
                                        We use Presto, a SQL-like language made specifically to query Big Data.</p>

                                    <h6>Wait, so this is not SQL? Then where can I find the resources to properly learn
                                        Presto?</h6>
                                    </a><p><a id="list-topic1">You can find the Presto Documentation </a><a href="#">here</a>. The section on how to
                                        query data
                                        can be found in the following section: SQL Statement Syntax</p>
                                </div>

                            <br />
                        </div>
                    </div>
                )
            }, 
            {
                outline: "On Long Queries",
                content: (
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="list-topic1" role="tabpanel" aria-labelledby="list-topic1-list">
                            <div id="list-topic1"><a id="list-topic1">
                                    <h3>Lesson 4 Complex Queries and Other Tips &amp; Tricks</h3>
                                    <h4>FAQs</h4>
                                    <h6>Given a complex requirement, how should I go about writing my SQL query?</h6>
                                    <p>A good way to do this would be to compartmentalise the requirements. You can
                                        start off by
                                        listing down the columns required for you to retrieve the data, this can include
                                        simple
                                        columns such as IDs, calculated columns such as GMV, as well as aggregated
                                        columns such as
                                        total purchase. Isolating these would allow you to identify the tables you would
                                        need in
                                        your final query.</p>
                                    <p>For example, let's say that we are tasked to retrieve the average number of
                                        Orders in each
                                        checkout for every country made in the last three months by users who were
                                        active over the
                                        last seven days. The initial requirement is simple as it requires only one
                                        column, which is
                                        the average number of orders per checkout. We can definitely isolate the table
                                        required to
                                        get our results, and that table would be order_mart__order_profile. Since the
                                        required
                                        column (average number of Orders in each checkout) is an aggregated column, we
                                        would have to
                                        get the initial column before the aggregation. In this case the column would be
                                        the number
                                        of orders in each checkout. We would then have to think of the column that I
                                        would have to
                                        aggregate our data over, which in this case would be the checkoutid.</p>
                                    <p>From these, I can then start with a simple query:</p>

                                    <p class="code-format"><span id="sql-term">SELECT </span>
                                        count(*)
                                        <span id="sql-term"> FROM </span>
                                        order_mart__order_profile op
                                        <span id="sql-term"> GROUP BY </span>
                                        op.checkoutid
                                    </p>

                                    <p>Applying the required aggregation:</p>
                                    <p class="code-format"><span id="sql-term">SELECT </span>
                                        AVG(n_orders)
                                        <span id="sql-term"> FROM </span>
                                        (<span id="sql-term">SELECT </span>
                                        count(*) <span id="sql-term"> AS </span> n_orders
                                        <span id="sql-term"> FROM </span>
                                        order_mart__order_profile op
                                        <span id="sql-term"> GROUP BY </span>
                                        op.checkoutid) orders
                                    </p>
                                    <p>The next thing to do would be to list down the various filters you need to narrow
                                        down your
                                        data. In this particular scenario, there are two conditions that has been
                                        explicitly
                                        specified:</p>
                                    <ol>
                                        <li>Checkouts within the last three months</li>
                                        <li>User is active over the last seven days.</li>
                                    </ol>
                                    <p>After the conditions are specified, first check if these conditions can be
                                        matched within the
                                        current query that you have. The first condition can be easily solved with our
                                        current set
                                        of tables, hence we can just add it into the query.</p>
                                    <p class="code-format"><span id="sql-term">SELECT </span>
                                        AVG(n_orders)
                                        <span id="sql-term"> FROM </span>
                                        (<span id="sql-term">SELECT </span>
                                        count(*) <span id="sql-term"> AS </span> n_orders
                                        <span id="sql-term"> FROM </span>
                                        order_mart__order_profile op
                                        <span id="sql-term"> WHERE </span>
                                        op.grass_date &gt;= current_date - interval '3' month
                                        <span id="sql-term"> GROUP BY </span>
                                        op.checkoutid
                                        ) orders
                                    </p>
                                    <p>The second would require us to retrieve the condition from other tables, in this
                                        case the
                                        table in question will be the user_profile. The sub query that we will need will
                                        be.</p>
                                    <p class="code-format"><span id="sql-term">SELECT </span>
                                        distinct userid
                                        <span id="sql-term"> FROM </span>
                                        user_profile
                                        <span id="sql-term"> WHERE </span>
                                        last_login &gt;= date('DATE_HERE')
                                    </p>

                                    <p>To properly join these two tables, I will first need to make sure that I have to
                                        include the
                                        userid for each checkout in the query. Then, we can join it with the above
                                        subquery to
                                        obtain the final query:</p>
                                    <p class="code-format"><span id="sql-term">SELECT </span> AVG(n_orders)
                                        <span id="sql-term"> FROM </span>
                                        (<span id="sql-term">SELECT </span> userid,
                                        checkoutid,
                                        count(*) <span id="sql-term"> AS </span> n_orders
                                        <span id="sql-term"> FROM </span> order_mart__order_profile op
                                        <span id="sql-term"> WHERE </span> grass_date &gt;= current_date - interval '3' month
                                        <span id="sql-term"> GROUP BY </span> userid, checkoutid) orders
                                        <span id="sql-term"> LEFT JOIN </span>
                                        (<span id="sql-term">SELECT </span> DISTINCT userid
                                        <span id="sql-term"> FROM </span> user_profile
                                        <span id="sql-term"> WHERE </span> last_login &gt;= date('DATE_HERE')) AT ON ct.userid = AT.userid
                                        <span id="sql-term"> WHERE </span> AT.userid <span id="sql-term">IS</span> <span id="sql-term">NOT</span> NULL
                                    </p>
                                </a></div>
                            <br />
                        </div>
                    </div>
                )
            },
            {
                outline: "On Dealing with Tables",
                nextQuiz: "/SQLTraining/quiz/Lesson4/L1Q4-Q1",
                content: (
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="list-topic1" role="tabpanel" aria-labelledby="list-topic1-list">
                            <div id="list-topic1"><a id="list-topic1">
                                    <h3>Lesson 4 Complex Queries and Other Tips &amp; Tricks</h3>
                                    <h4>FAQs</h4>
                                    <h6>What's the deal with lists in some tables, how do I separate them into
                                        individual
                                        rows? For
                                        example:</h6>
                                    <p class="code-format"><span id="sql-term">SELECT </span>
                                        collection_id,
                                        data
                                        <span id="sql-term"> FROM </span>
                                        shopee_item_db__collection_tab;
                                    </p>
                                    <p>Short answer: You can make use of <span id="sql-term"> CROSS JOIN </span> and <span id="sql-term"> UNNEST </span>.</p>
                                    <p class="code-format"><span id="sql-term">SELECT </span> collection_id,
                                        item['shopid']<span id="sql-term"> AS </span> shopid,
                                        item['itemid']<span id="sql-term"> AS </span> itemid
                                        <span id="sql-term"> FROM </span> shopee_item_db__collection_tab
                                        <span id="sql-term"> CROSS JOIN </span> <span id="sql-term"> UNNEST </span> (CAST (json_extract (data,'$') <span id="sql-term"> AS </span> <span id="sql-term">ARRAY</span> (MAP
                                        (VARCHAR,BIGINT))))
                                        <span id="sql-term"> AS </span> t
                                        (item)
                                    </p>
                                    <p>Long answer: To separate data into individual rows, we can make use of <span id="sql-term"> CROSS JOIN </span>
                                        and
                                        <span id="sql-term"> UNNEST </span>
                                        functions in Presto. <span id="sql-term"> CROSS JOIN </span> returns a Cartesian product of two relations.
                                        <span id="sql-term"> UNNEST </span>
                                        expands
                                        an array into a relation. If we look at our data, in this case column data in
                                        shopee_item_db__collection_tab, it is a list of data pairs consisting of two
                                        keys -
                                        shopid
                                        and itemid. It is in json format but the data type is varchar. This is an
                                        example of
                                        how the
                                        data looks like:</p>
                                    <p class="code-format">
                                        {`[{"shopid":2145165,"itemid":10449936},{"shopid":1168601,"itemid":2434241}]`}
                                    </p>
                                    <p>The mapping of key to value in json is also classified as MAP in Presto. In the
                                        above
                                        example, a key called "shopid" has value 2145165. The key is in Varchar data
                                        type
                                        and value
                                        is in BigInt datatype. This column can
                                        then be represented as an array of a map of a given Varchar key and BigInt value
                                        or
                                        written
                                        as <span id="sql-term">ARRAY</span> (MAP(VARCHAR,BIGINT)).
                                    </p>
                                    <p>Presto supports conversion from JSON to Array. Since our data is already in json
                                        format, we
                                        can simply convert it into a json string using json_extract(json, json_path). If
                                        we
                                        only
                                        need a specific key then we have to define it in the json_path. For example, if
                                        we
                                        are
                                        looking for itemid key, json_path is $.itemid. In this case, we extract
                                        everything
                                        so it is
                                        simply $. Thus, the whole expression is:</p>
                                    <p class="code-format">CAST (json_extract (data,'$') <span id="sql-term"> AS </span> <span id="sql-term">ARRAY</span> (MAP
                                        (VARCHAR,BIGINT)))
                                    </p>
                                    <p>Now we can go back to the first step, which is making use of <span id="sql-term"> CROSS JOIN </span> and
                                        <span id="sql-term"> UNNEST </span>.
                                        As
                                        mentioned earlier, <span id="sql-term"> UNNEST </span> expands an array into a relation. Our data is already
                                        an
                                        array
                                        now. We can finally use the <span id="sql-term"> CROSS JOIN </span> and <span id="sql-term"> UNNEST </span> to expand the data into
                                        multiple
                                        rows. To
                                        avoid potential ambiguity, we give the new column an alias, e.g. item. Do not
                                        forget
                                        that
                                        this new column is a map of two keys: shopid and itemid. If we want to grab the
                                        shopids, we
                                        should <span id="sql-term">SELECT </span> item['shopid']. Apply into the itemid
                                        similarly.</p>

                                    <br />
                                </a></div>
                        </div>
                    </div>
                )
            }
        ]
    }
}

export default data;
