/**
 * 
 * @param {string} header 
 * @param {ReactDOM} body
 * @param {string / image import} image
 */
const Subheader = ({ header, body, image }) => {
    return (
        <div className="subheader">
            <div>
                <h1>{ header }</h1>
                { body }
            </div>
            <div>
                <img src={image} />
            </div>
        </div>
    )
}

export default Subheader;