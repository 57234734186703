import Learn from '../../assets/images/login/learn.png';
import Practice from '../../assets/images/login/practice.png';
import Progress from '../../assets/images/login/progress.png';

const Info = () => (
    <div className="info">
        <div className="info__flexed">
            <img src={Learn} />
            <h2>Learn How to Create Queries</h2>
            <p>
                Go over our curated modules to learn more about creating SQL queries
            </p>
        </div>
        <div className="info__flexed">
            <img src={Practice} />
            <h2>Practice through Quizzes</h2>
            <p>
                Try your querying skills on our set of quizzes and practice area
            </p>
        </div>
        <div className="info__flexed">
            <img src={Progress} />
            <h2>Track Your Progress</h2>
            <p>
                See how much you have progressed from learning the modules
            </p>
        </div>
    </div>
);

export default Info;