import { useParams, Link } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';
import BookIcon from "../../assets/images/book_icon.png";
import LockIcon from "../../assets/images/lock_icon.png";

const LessonList = ({ 
  hideBookPic, 
  listHeader, 
  lessons, 
  sqlTraining, 
  lessonPlan,
  quizesProgress,
  pageProgress
}) => {
  const keys = Object.keys(lessons);

  // https://stackoverflow.com/questions/4364881/inserting-string-at-position-x-of-another-string/4364902
  const insertAtPos = (original, toInsert, position) => {
    return original ? 
      [original.slice(0, position), toInsert, original.slice(position)].join('') 
      : null;
  }

  let progress = 0;
  let total = 0;
  let totalDone = 0;
  const donePagesKeys = pageProgress 
    ? Object.keys(pageProgress) 
    : [];
  const lessonKeys = Object.keys(lessons);

  switch(lessonPlan) {
    case "SQLTraining":
      const doneQuizesKeys = quizesProgress 
        ? Object.keys(quizesProgress) 
        : [];
      lessonKeys.forEach(lessonKey => {
        const { lesson, questions } = lessons[lessonKey];
        total += (lesson.length + questions.length)
      });

      doneQuizesKeys?.forEach(doneQuizesKey => {
        if (quizesProgress[doneQuizesKey]) {
          totalDone += 1;
        }
      });

      donePagesKeys?.forEach(donePageKey => {
        const donePage = pageProgress[donePageKey];
        if (donePageKey.includes('L1-') && donePage) {
          totalDone += 1;
        }
      })

      break;
    default:
      let iniSlug = "";
      lessonKeys.forEach(lessonKey => {
        const { lesson } = lessons[lessonKey];
        total += lesson?.length
      });

      if (lessonPlan === 'PrestoSQLOptimization') {
        iniSlug = "L2-";
      }
      else if (lessonPlan === 'LumosUserGuide') {
        iniSlug = "L3-";
      }
      else if (lessonPlan === 'PrestoDocumentation') {
        iniSlug = "L4-";
        total = 1; // hard coding dis becuase it diperent
      }

      donePagesKeys?.forEach(donePageKey => {
        const donePage = pageProgress[donePageKey];
        if (donePageKey.includes(iniSlug) && donePage) {
          totalDone += 1;
        }
      })
  }
  progress = ((totalDone / total) * 100).toFixed(2);
  progress = progress > 100 ? 100.00 : progress;
  return (
    <div className="lessonList">
      <div className="lessonList__header">
        <div>
          {!hideBookPic &&
            <img alt="lessons-icon" src={BookIcon} />
          }
          <h2>{listHeader || "Lessons"}</h2>
        </div>
        <div>
          <ProgressBar striped variant="warning" animated now={progress} />
          <p>{progress}%</p>
        </div>
      </div>
      <div className="lessonList__body">
        {
          keys.map((key, index) => {
            const { 
              title, 
              quiz, 
              questions,
            } = lessons[key];
            return (
              <div>
                <Link to={`/${lessonPlan}/${key}/1`} className="lessonList__lesson">
                  <p>
                    {title}
                  </p>
                </Link>
                {(sqlTraining) &&
                  <div className='lessonList__body__quizes'>
                    <h6>{quiz}
                      {
                        questions.map((question, index) => {
                          // needed to format it the day it is in the db
                          // check if previous quiz is answer, if it is not, then keep it 'locked'
                          const progressSlug = insertAtPos(questions?.[index - 1]?.replace('-Q', '-'), '-', 2)
                          const linkReturn = (
                            ((quizesProgress && quizesProgress[progressSlug]) || index === 0)
                              ? <Link to={`/SQLTraining/quiz/${key}/${question}`}>Question {index + 1}</Link>
                              : ( 
                                  <b className="lessonList__body__quizes__locked">
                                    <img alt="lock-icon" src={LockIcon} /> Question {index + 1} 
                                  </b>
                                )
                          )

                          return <>{ linkReturn } { questions[index + 1] ? ' | ' : ''}</>;
                        })
                      }
                    </h6>
                  </div>
                }
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default LessonList;