import { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { getAuth, signOut } from "firebase/auth";

import SLogo from "../assets/images/logos/shopee_navbar.png";
import Avatar from "../assets/images/navigation/account_icon.png";
import Arrow from "../assets/images/navigation/arrowbar_right.png";

import AppContext from "./reusables/AppContext";

const Navigation = () => {
    const { user } = useContext(AppContext);
    const location = useLocation();
    const navigate = useNavigate();
    const toHide = [
        '/login',
        '/signup',
        '/reset'
    ];

    return (
        <div 
            style={{
                display: toHide.includes(location.pathname) 
                    ? 'none' 
                    : ''
            }} 
            className="navigation"
         >
            <div className="navigation__left">
                <Link to="/">
                    <img src={SLogo} />
                    <h1>Query Training 
                        Portal</h1>
                </Link>
                <Link to="/progress">
                    My Progress
                </Link>
                <Link to="/playground">
                    Training Ground
                </Link>
                <Link to="/about">
                    About
                </Link>
            </div>
            <div className="navigation__right">
                <img src={Avatar} />
                <p>Welcome, <b>{ user?.email }</b>!</p>
                <Button onClick={() => {
                    signOut(getAuth()).then(() => {
                        window.location.href='/login';
                      }).catch((error) => {
                        // An error happened.
                      });
                }}>
                    LOGOUT
                    <img src={Arrow} />
                </Button>
            </div>
        </div>
    )
}

export default Navigation;