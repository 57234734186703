import { useParams } from "react-router-dom";
import LessonLayout from "./LessonLayout";
import lessons from '../../assets/lessons/lumos';
import Subheader from '../reusables/Subheader';

const LumosLesson = () => {
    const { lessonKey } = useParams();
    const { lesson } = lessons[lessonKey];
    return (
        <div className="lumosLesson">
            <Subheader 
                header="Lumos User Guide"
                body={
                    <div>
                        <p>5 lessons</p>
                        <p>Learn how to do basic SQL queries and create dashboards & reports on Lumos.</p>
                    </div>
                }
            />
            <LessonLayout
                lesson={lesson}
                lessonKey={lessonKey}
                lessonPlan="LumosUserGuide"
            />
        </div>
    )
}

export default LumosLesson;