import YT from '../assets/images/logos/yt-logo.png';
import FB from '../assets/images/logos/fb-logo.png';
import TW from '../assets/images/logos/twitter-logo.png';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__columns">
                <ul>
                    <h2>ABOUT SHOPEE</h2>
                    <li><a href="https://shopee.ph/">Shopee</a></li>
                </ul>
                <ul>
                    <h2>FOLLOW US</h2>
                    <li>
                        <img src={FB} />
                        <a href="https://www.facebook.com/ShopeePH/">Facebook</a>
                    </li>
                    <li>
                        <img src={YT} />
                        <a href="https://www.youtube.com/channel/UCBjpgBUXPLoCAQbjWoVbnrA">Youtube</a>
                    </li>
                    <li>
                        <img src={TW} />
                        <a href="https://twitter.com/ShopeePH">Twitter</a>
                    </li>
                </ul>
                <ul>
                    <h2>CONTACT US</h2>
                    <li>
                        <a href="https://shopee.ph/web">Email</a>
                    </li>
                </ul>
                <ul>
                    <h2>SHOPEE APP DOWNLOAD</h2>
                    <li>
                        <a href="https://itunes.apple.com/ph/app/id959841854?mt=8">App Store</a>
                    </li>
                    <li>
                        <a href="https://play.google.com/store/apps/details?id=com.shopee.ph&referrer=af_tranid%3DHMLRSYBJEMQMzb43gtsh9A%26pid%3DOrganicA%26c%3Dlp_home_and1">Google Play</a>
                    </li>
                    <li>
                        <a href="https://appgallery.cloud.huawei.com/ag/n/app/C100706415?channelId=web&detailType=0">App Gallery</a>
                    </li>
                </ul>
            </div>
            <p>Query Training Portal | Shopee Philippines 2021</p>
        </footer>
    )
}

export default Footer;
