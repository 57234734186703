import { Button } from 'react-bootstrap';

const SButton = ({ 
    className, 
    children, 
    onClick, 
    disabled 
}) => (
    <Button disabled={disabled} className={`sButton ${className}`} onClick={onClick}>
        { children }
    </Button>
)

export default SButton;